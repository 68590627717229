/**
 * Do not edit directly
 * Generated on Thu, 12 Dec 2024 09:57:30 GMT
 */

module.exports = {
  "spacing": {
    "paddingXs": {
      "value": "4px",
      "type": "number",
      "unit": "pixel",
      "category": "spacing",
      "exportKey": "spacing",
      "filePath": "properties/design-tokens.json",
      "isSource": true,
      "original": {
        "value": 4,
        "type": "number",
        "unit": "pixel",
        "category": "spacing",
        "exportKey": "spacing"
      },
      "name": "SpacingPaddingXs",
      "attributes": {
        "category": "spacing",
        "type": "paddingXs"
      },
      "path": [
        "spacing",
        "paddingXs"
      ]
    },
    "gutterS": {
      "value": "16px",
      "type": "number",
      "unit": "pixel",
      "category": "spacing",
      "exportKey": "spacing",
      "filePath": "properties/design-tokens.json",
      "isSource": true,
      "original": {
        "value": 16,
        "type": "number",
        "unit": "pixel",
        "category": "spacing",
        "exportKey": "spacing"
      },
      "name": "SpacingGutterS",
      "attributes": {
        "category": "spacing",
        "type": "gutterS"
      },
      "path": [
        "spacing",
        "gutterS"
      ]
    },
    "paddingM": {
      "value": "16px",
      "type": "number",
      "unit": "pixel",
      "category": "spacing",
      "exportKey": "spacing",
      "filePath": "properties/design-tokens.json",
      "isSource": true,
      "original": {
        "value": 16,
        "type": "number",
        "unit": "pixel",
        "category": "spacing",
        "exportKey": "spacing"
      },
      "name": "SpacingPaddingM",
      "attributes": {
        "category": "spacing",
        "type": "paddingM"
      },
      "path": [
        "spacing",
        "paddingM"
      ]
    },
    "paddingS": {
      "value": "12px",
      "type": "number",
      "unit": "pixel",
      "category": "spacing",
      "exportKey": "spacing",
      "filePath": "properties/design-tokens.json",
      "isSource": true,
      "original": {
        "value": 12,
        "type": "number",
        "unit": "pixel",
        "category": "spacing",
        "exportKey": "spacing"
      },
      "name": "SpacingPaddingS",
      "attributes": {
        "category": "spacing",
        "type": "paddingS"
      },
      "path": [
        "spacing",
        "paddingS"
      ]
    },
    "gutterM": {
      "value": "24px",
      "type": "number",
      "unit": "pixel",
      "category": "spacing",
      "exportKey": "spacing",
      "filePath": "properties/design-tokens.json",
      "isSource": true,
      "original": {
        "value": 24,
        "type": "number",
        "unit": "pixel",
        "category": "spacing",
        "exportKey": "spacing"
      },
      "name": "SpacingGutterM",
      "attributes": {
        "category": "spacing",
        "type": "gutterM"
      },
      "path": [
        "spacing",
        "gutterM"
      ]
    },
    "horizontalXxxl": {
      "value": "80px",
      "type": "number",
      "unit": "pixel",
      "category": "spacing",
      "exportKey": "spacing",
      "filePath": "properties/design-tokens.json",
      "isSource": true,
      "original": {
        "value": 80,
        "type": "number",
        "unit": "pixel",
        "category": "spacing",
        "exportKey": "spacing"
      },
      "name": "SpacingHorizontalXxxl",
      "attributes": {
        "category": "spacing",
        "type": "horizontalXxxl"
      },
      "path": [
        "spacing",
        "horizontalXxxl"
      ]
    },
    "verticalStories": {
      "value": "56px",
      "type": "number",
      "unit": "pixel",
      "category": "spacing",
      "exportKey": "spacing",
      "filePath": "properties/design-tokens.json",
      "isSource": true,
      "original": {
        "value": 56,
        "type": "number",
        "unit": "pixel",
        "category": "spacing",
        "exportKey": "spacing"
      },
      "name": "SpacingVerticalStories",
      "attributes": {
        "category": "spacing",
        "type": "verticalStories"
      },
      "path": [
        "spacing",
        "verticalStories"
      ]
    },
    "verticalXxxl": {
      "value": "48px",
      "type": "number",
      "unit": "pixel",
      "category": "spacing",
      "exportKey": "spacing",
      "filePath": "properties/design-tokens.json",
      "isSource": true,
      "original": {
        "value": 48,
        "type": "number",
        "unit": "pixel",
        "category": "spacing",
        "exportKey": "spacing"
      },
      "name": "SpacingVerticalXxxl",
      "attributes": {
        "category": "spacing",
        "type": "verticalXxxl"
      },
      "path": [
        "spacing",
        "verticalXxxl"
      ]
    },
    "verticalXxl": {
      "value": "40px",
      "type": "number",
      "unit": "pixel",
      "category": "spacing",
      "exportKey": "spacing",
      "filePath": "properties/design-tokens.json",
      "isSource": true,
      "original": {
        "value": 40,
        "type": "number",
        "unit": "pixel",
        "category": "spacing",
        "exportKey": "spacing"
      },
      "name": "SpacingVerticalXxl",
      "attributes": {
        "category": "spacing",
        "type": "verticalXxl"
      },
      "path": [
        "spacing",
        "verticalXxl"
      ]
    },
    "verticalXl": {
      "value": "32px",
      "type": "number",
      "unit": "pixel",
      "category": "spacing",
      "exportKey": "spacing",
      "filePath": "properties/design-tokens.json",
      "isSource": true,
      "original": {
        "value": 32,
        "type": "number",
        "unit": "pixel",
        "category": "spacing",
        "exportKey": "spacing"
      },
      "name": "SpacingVerticalXl",
      "attributes": {
        "category": "spacing",
        "type": "verticalXl"
      },
      "path": [
        "spacing",
        "verticalXl"
      ]
    },
    "verticalSection": {
      "value": "64px",
      "type": "number",
      "unit": "pixel",
      "category": "spacing",
      "exportKey": "spacing",
      "filePath": "properties/design-tokens.json",
      "isSource": true,
      "original": {
        "value": 64,
        "type": "number",
        "unit": "pixel",
        "category": "spacing",
        "exportKey": "spacing"
      },
      "name": "SpacingVerticalSection",
      "attributes": {
        "category": "spacing",
        "type": "verticalSection"
      },
      "path": [
        "spacing",
        "verticalSection"
      ]
    },
    "verticalS": {
      "value": "12px",
      "type": "number",
      "unit": "pixel",
      "category": "spacing",
      "exportKey": "spacing",
      "filePath": "properties/design-tokens.json",
      "isSource": true,
      "original": {
        "value": 12,
        "type": "number",
        "unit": "pixel",
        "category": "spacing",
        "exportKey": "spacing"
      },
      "name": "SpacingVerticalS",
      "attributes": {
        "category": "spacing",
        "type": "verticalS"
      },
      "path": [
        "spacing",
        "verticalS"
      ]
    },
    "horizontalS": {
      "value": "12px",
      "type": "number",
      "unit": "pixel",
      "category": "spacing",
      "exportKey": "spacing",
      "filePath": "properties/design-tokens.json",
      "isSource": true,
      "original": {
        "value": 12,
        "type": "number",
        "unit": "pixel",
        "category": "spacing",
        "exportKey": "spacing"
      },
      "name": "SpacingHorizontalS",
      "attributes": {
        "category": "spacing",
        "type": "horizontalS"
      },
      "path": [
        "spacing",
        "horizontalS"
      ]
    },
    "horizontalXs": {
      "value": "8px",
      "type": "number",
      "unit": "pixel",
      "category": "spacing",
      "exportKey": "spacing",
      "filePath": "properties/design-tokens.json",
      "isSource": true,
      "original": {
        "value": 8,
        "type": "number",
        "unit": "pixel",
        "category": "spacing",
        "exportKey": "spacing"
      },
      "name": "SpacingHorizontalXs",
      "attributes": {
        "category": "spacing",
        "type": "horizontalXs"
      },
      "path": [
        "spacing",
        "horizontalXs"
      ]
    },
    "verticalXs": {
      "value": "8px",
      "type": "number",
      "unit": "pixel",
      "category": "spacing",
      "exportKey": "spacing",
      "filePath": "properties/design-tokens.json",
      "isSource": true,
      "original": {
        "value": 8,
        "type": "number",
        "unit": "pixel",
        "category": "spacing",
        "exportKey": "spacing"
      },
      "name": "SpacingVerticalXs",
      "attributes": {
        "category": "spacing",
        "type": "verticalXs"
      },
      "path": [
        "spacing",
        "verticalXs"
      ]
    },
    "verticalXxs": {
      "value": "4px",
      "type": "number",
      "unit": "pixel",
      "category": "spacing",
      "exportKey": "spacing",
      "filePath": "properties/design-tokens.json",
      "isSource": true,
      "original": {
        "value": 4,
        "type": "number",
        "unit": "pixel",
        "category": "spacing",
        "exportKey": "spacing"
      },
      "name": "SpacingVerticalXxs",
      "attributes": {
        "category": "spacing",
        "type": "verticalXxs"
      },
      "path": [
        "spacing",
        "verticalXxs"
      ]
    },
    "verticalM": {
      "value": "16px",
      "type": "number",
      "unit": "pixel",
      "category": "spacing",
      "exportKey": "spacing",
      "filePath": "properties/design-tokens.json",
      "isSource": true,
      "original": {
        "value": 16,
        "type": "number",
        "unit": "pixel",
        "category": "spacing",
        "exportKey": "spacing"
      },
      "name": "SpacingVerticalM",
      "attributes": {
        "category": "spacing",
        "type": "verticalM"
      },
      "path": [
        "spacing",
        "verticalM"
      ]
    },
    "verticalL": {
      "value": "24px",
      "type": "number",
      "unit": "pixel",
      "category": "spacing",
      "exportKey": "spacing",
      "filePath": "properties/design-tokens.json",
      "isSource": true,
      "original": {
        "value": 24,
        "type": "number",
        "unit": "pixel",
        "category": "spacing",
        "exportKey": "spacing"
      },
      "name": "SpacingVerticalL",
      "attributes": {
        "category": "spacing",
        "type": "verticalL"
      },
      "path": [
        "spacing",
        "verticalL"
      ]
    }
  },
  "breakpoint": {
    "xs": {
      "category": "breakpoint",
      "minWidth": {
        "value": "360px",
        "unit": "pixel",
        "filePath": "properties/breakpoints.json",
        "isSource": true,
        "original": {
          "value": 360,
          "unit": "pixel"
        },
        "name": "BreakpointXsMinWidth",
        "attributes": {
          "category": "breakpoint",
          "type": "xs",
          "item": "minWidth"
        },
        "path": [
          "breakpoint",
          "xs",
          "minWidth"
        ]
      },
      "minHeight": {
        "value": "640px",
        "unit": "pixel",
        "filePath": "properties/breakpoints.json",
        "isSource": true,
        "original": {
          "value": 640,
          "unit": "pixel"
        },
        "name": "BreakpointXsMinHeight",
        "attributes": {
          "category": "breakpoint",
          "type": "xs",
          "item": "minHeight"
        },
        "path": [
          "breakpoint",
          "xs",
          "minHeight"
        ]
      }
    },
    "sm": {
      "category": "breakpoint",
      "minWidth": {
        "value": "600px",
        "unit": "pixel",
        "filePath": "properties/breakpoints.json",
        "isSource": true,
        "original": {
          "value": 600,
          "unit": "pixel"
        },
        "name": "BreakpointSmMinWidth",
        "attributes": {
          "category": "breakpoint",
          "type": "sm",
          "item": "minWidth"
        },
        "path": [
          "breakpoint",
          "sm",
          "minWidth"
        ]
      },
      "minHeight": {
        "value": "1024px",
        "unit": "pixel",
        "filePath": "properties/breakpoints.json",
        "isSource": true,
        "original": {
          "value": 1024,
          "unit": "pixel"
        },
        "name": "BreakpointSmMinHeight",
        "attributes": {
          "category": "breakpoint",
          "type": "sm",
          "item": "minHeight"
        },
        "path": [
          "breakpoint",
          "sm",
          "minHeight"
        ]
      }
    },
    "md": {
      "category": "breakpoint",
      "minWidth": {
        "value": "960px",
        "unit": "pixel",
        "filePath": "properties/breakpoints.json",
        "isSource": true,
        "original": {
          "value": 960,
          "unit": "pixel"
        },
        "name": "BreakpointMdMinWidth",
        "attributes": {
          "category": "breakpoint",
          "type": "md",
          "item": "minWidth"
        },
        "path": [
          "breakpoint",
          "md",
          "minWidth"
        ]
      },
      "minHeight": {
        "value": "540px",
        "unit": "pixel",
        "filePath": "properties/breakpoints.json",
        "isSource": true,
        "original": {
          "value": 540,
          "unit": "pixel"
        },
        "name": "BreakpointMdMinHeight",
        "attributes": {
          "category": "breakpoint",
          "type": "md",
          "item": "minHeight"
        },
        "path": [
          "breakpoint",
          "md",
          "minHeight"
        ]
      }
    },
    "lg": {
      "category": "breakpoint",
      "minWidth": {
        "value": "1280px",
        "unit": "pixel",
        "filePath": "properties/breakpoints.json",
        "isSource": true,
        "original": {
          "value": 1280,
          "unit": "pixel"
        },
        "name": "BreakpointLgMinWidth",
        "attributes": {
          "category": "breakpoint",
          "type": "lg",
          "item": "minWidth"
        },
        "path": [
          "breakpoint",
          "lg",
          "minWidth"
        ]
      },
      "minHeight": {
        "value": "720px",
        "unit": "pixel",
        "filePath": "properties/breakpoints.json",
        "isSource": true,
        "original": {
          "value": 720,
          "unit": "pixel"
        },
        "name": "BreakpointLgMinHeight",
        "attributes": {
          "category": "breakpoint",
          "type": "lg",
          "item": "minHeight"
        },
        "path": [
          "breakpoint",
          "lg",
          "minHeight"
        ]
      }
    },
    "xl": {
      "category": "breakpoint",
      "minWidth": {
        "value": "1920px",
        "unit": "pixel",
        "filePath": "properties/breakpoints.json",
        "isSource": true,
        "original": {
          "value": 1920,
          "unit": "pixel"
        },
        "name": "BreakpointXlMinWidth",
        "attributes": {
          "category": "breakpoint",
          "type": "xl",
          "item": "minWidth"
        },
        "path": [
          "breakpoint",
          "xl",
          "minWidth"
        ]
      },
      "minHeight": {
        "value": "1080px",
        "unit": "pixel",
        "filePath": "properties/breakpoints.json",
        "isSource": true,
        "original": {
          "value": 1080,
          "unit": "pixel"
        },
        "name": "BreakpointXlMinHeight",
        "attributes": {
          "category": "breakpoint",
          "type": "xl",
          "item": "minHeight"
        },
        "path": [
          "breakpoint",
          "xl",
          "minHeight"
        ]
      }
    }
  },
  "sizes": {
    "lineLength": {
      "category": "size",
      "exportKey": "size",
      "value": "744px",
      "type": "number",
      "unit": "pixel",
      "filePath": "properties/design-tokens.json",
      "isSource": true,
      "original": {
        "category": "size",
        "exportKey": "size",
        "value": 744,
        "type": "number",
        "unit": "pixel"
      },
      "name": "SizesLineLength",
      "attributes": {
        "category": "sizes",
        "type": "lineLength"
      },
      "path": [
        "sizes",
        "lineLength"
      ]
    },
    "textField": {
      "category": "size",
      "exportKey": "size",
      "value": "280px",
      "type": "number",
      "unit": "pixel",
      "filePath": "properties/design-tokens.json",
      "isSource": true,
      "original": {
        "category": "size",
        "exportKey": "size",
        "value": 280,
        "type": "number",
        "unit": "pixel"
      },
      "name": "SizesTextField",
      "attributes": {
        "category": "sizes",
        "type": "textField"
      },
      "path": [
        "sizes",
        "textField"
      ]
    }
  },
  "radii": {
    "none": {
      "category": "radius",
      "exportKey": "radius",
      "radiusType": {
        "value": "mixed",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "mixed",
          "type": "string"
        },
        "name": "RadiiNoneRadiusType",
        "attributes": {
          "category": "radii",
          "type": "none",
          "item": "radiusType"
        },
        "path": [
          "radii",
          "none",
          "radiusType"
        ]
      },
      "radii": {
        "topLeft": {
          "value": "0px",
          "type": "number",
          "unit": "pixel",
          "filePath": "properties/design-tokens.json",
          "isSource": true,
          "original": {
            "value": 0,
            "type": "number",
            "unit": "pixel"
          },
          "name": "RadiiNoneRadiiTopLeft",
          "attributes": {
            "category": "radii",
            "type": "none",
            "item": "radii",
            "subitem": "topLeft"
          },
          "path": [
            "radii",
            "none",
            "radii",
            "topLeft"
          ]
        },
        "topRight": {
          "value": "0px",
          "type": "number",
          "unit": "pixel",
          "filePath": "properties/design-tokens.json",
          "isSource": true,
          "original": {
            "value": 0,
            "type": "number",
            "unit": "pixel"
          },
          "name": "RadiiNoneRadiiTopRight",
          "attributes": {
            "category": "radii",
            "type": "none",
            "item": "radii",
            "subitem": "topRight"
          },
          "path": [
            "radii",
            "none",
            "radii",
            "topRight"
          ]
        },
        "bottomRight": {
          "value": "0px",
          "type": "number",
          "unit": "pixel",
          "filePath": "properties/design-tokens.json",
          "isSource": true,
          "original": {
            "value": 0,
            "type": "number",
            "unit": "pixel"
          },
          "name": "RadiiNoneRadiiBottomRight",
          "attributes": {
            "category": "radii",
            "type": "none",
            "item": "radii",
            "subitem": "bottomRight"
          },
          "path": [
            "radii",
            "none",
            "radii",
            "bottomRight"
          ]
        },
        "bottomLeft": {
          "value": "0px",
          "type": "number",
          "unit": "pixel",
          "filePath": "properties/design-tokens.json",
          "isSource": true,
          "original": {
            "value": 0,
            "type": "number",
            "unit": "pixel"
          },
          "name": "RadiiNoneRadiiBottomLeft",
          "attributes": {
            "category": "radii",
            "type": "none",
            "item": "radii",
            "subitem": "bottomLeft"
          },
          "path": [
            "radii",
            "none",
            "radii",
            "bottomLeft"
          ]
        }
      },
      "smoothing": {
        "value": 0.65,
        "type": "number",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 0.65,
          "type": "number"
        },
        "name": "RadiiNoneSmoothing",
        "attributes": {
          "category": "radii",
          "type": "none",
          "item": "smoothing"
        },
        "path": [
          "radii",
          "none",
          "smoothing"
        ]
      }
    },
    "rounded": {
      "category": "radius",
      "exportKey": "radius",
      "radius": {
        "value": "4px",
        "type": "number",
        "unit": "pixel",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 4,
          "type": "number",
          "unit": "pixel"
        },
        "name": "RadiiRoundedRadius",
        "attributes": {
          "category": "radii",
          "type": "rounded",
          "item": "radius"
        },
        "path": [
          "radii",
          "rounded",
          "radius"
        ]
      },
      "radiusType": {
        "value": "single",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "single",
          "type": "string"
        },
        "name": "RadiiRoundedRadiusType",
        "attributes": {
          "category": "radii",
          "type": "rounded",
          "item": "radiusType"
        },
        "path": [
          "radii",
          "rounded",
          "radiusType"
        ]
      },
      "radii": {
        "topLeft": {
          "value": "4px",
          "type": "number",
          "unit": "pixel",
          "filePath": "properties/design-tokens.json",
          "isSource": true,
          "original": {
            "value": 4,
            "type": "number",
            "unit": "pixel"
          },
          "name": "RadiiRoundedRadiiTopLeft",
          "attributes": {
            "category": "radii",
            "type": "rounded",
            "item": "radii",
            "subitem": "topLeft"
          },
          "path": [
            "radii",
            "rounded",
            "radii",
            "topLeft"
          ]
        },
        "topRight": {
          "value": "4px",
          "type": "number",
          "unit": "pixel",
          "filePath": "properties/design-tokens.json",
          "isSource": true,
          "original": {
            "value": 4,
            "type": "number",
            "unit": "pixel"
          },
          "name": "RadiiRoundedRadiiTopRight",
          "attributes": {
            "category": "radii",
            "type": "rounded",
            "item": "radii",
            "subitem": "topRight"
          },
          "path": [
            "radii",
            "rounded",
            "radii",
            "topRight"
          ]
        },
        "bottomRight": {
          "value": "4px",
          "type": "number",
          "unit": "pixel",
          "filePath": "properties/design-tokens.json",
          "isSource": true,
          "original": {
            "value": 4,
            "type": "number",
            "unit": "pixel"
          },
          "name": "RadiiRoundedRadiiBottomRight",
          "attributes": {
            "category": "radii",
            "type": "rounded",
            "item": "radii",
            "subitem": "bottomRight"
          },
          "path": [
            "radii",
            "rounded",
            "radii",
            "bottomRight"
          ]
        },
        "bottomLeft": {
          "value": "4px",
          "type": "number",
          "unit": "pixel",
          "filePath": "properties/design-tokens.json",
          "isSource": true,
          "original": {
            "value": 4,
            "type": "number",
            "unit": "pixel"
          },
          "name": "RadiiRoundedRadiiBottomLeft",
          "attributes": {
            "category": "radii",
            "type": "rounded",
            "item": "radii",
            "subitem": "bottomLeft"
          },
          "path": [
            "radii",
            "rounded",
            "radii",
            "bottomLeft"
          ]
        }
      },
      "smoothing": {
        "value": 0.65,
        "type": "number",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 0.65,
          "type": "number"
        },
        "name": "RadiiRoundedSmoothing",
        "attributes": {
          "category": "radii",
          "type": "rounded",
          "item": "smoothing"
        },
        "path": [
          "radii",
          "rounded",
          "smoothing"
        ]
      }
    }
  },
  "color": {
    "brandMain": {
      "category": "color",
      "exportKey": "color",
      "value": "#349cd5",
      "type": "color",
      "filePath": "properties/design-tokens.json",
      "isSource": true,
      "original": {
        "category": "color",
        "exportKey": "color",
        "value": "rgba(52, 156, 213, 1)",
        "type": "color"
      },
      "name": "ColorBrandMain",
      "attributes": {
        "category": "color",
        "type": "brandMain",
        "hex": "349cd5",
        "rgb": {
          "r": 52,
          "g": 156,
          "b": 213,
          "a": 1
        },
        "hsl": {
          "h": 201.24223602484471,
          "s": 0.6571428571428571,
          "l": 0.5196078431372549,
          "a": 1
        },
        "hsv": {
          "h": 201.24223602484471,
          "s": 0.7558685446009389,
          "v": 0.8352941176470589,
          "a": 1
        }
      },
      "path": [
        "color",
        "brandMain"
      ]
    },
    "brandLight": {
      "category": "color",
      "exportKey": "color",
      "value": "#d6ebf7",
      "type": "color",
      "filePath": "properties/design-tokens.json",
      "isSource": true,
      "original": {
        "category": "color",
        "exportKey": "color",
        "value": "rgba(214, 235, 247, 1)",
        "type": "color"
      },
      "name": "ColorBrandLight",
      "attributes": {
        "category": "color",
        "type": "brandLight",
        "hex": "d6ebf7",
        "rgb": {
          "r": 214,
          "g": 235,
          "b": 247,
          "a": 1
        },
        "hsl": {
          "h": 201.81818181818184,
          "s": 0.6734693877551025,
          "l": 0.903921568627451,
          "a": 1
        },
        "hsv": {
          "h": 201.81818181818184,
          "s": 0.13360323886639675,
          "v": 0.9686274509803922,
          "a": 1
        }
      },
      "path": [
        "color",
        "brandLight"
      ]
    },
    "brandDark": {
      "category": "color",
      "exportKey": "color",
      "value": "#247e9c",
      "type": "color",
      "filePath": "properties/design-tokens.json",
      "isSource": true,
      "original": {
        "category": "color",
        "exportKey": "color",
        "value": "rgba(36, 126, 156, 1)",
        "type": "color"
      },
      "name": "ColorBrandDark",
      "attributes": {
        "category": "color",
        "type": "brandDark",
        "hex": "247e9c",
        "rgb": {
          "r": 36,
          "g": 126,
          "b": 156,
          "a": 1
        },
        "hsl": {
          "h": 195,
          "s": 0.6250000000000001,
          "l": 0.3764705882352941,
          "a": 1
        },
        "hsv": {
          "h": 195,
          "s": 0.7692307692307693,
          "v": 0.611764705882353,
          "a": 1
        }
      },
      "path": [
        "color",
        "brandDark"
      ]
    },
    "textPrimary": {
      "category": "color",
      "exportKey": "color",
      "comment": "Use this for texts with high emphasis and active icons.",
      "value": "#111827",
      "type": "color",
      "filePath": "properties/design-tokens.json",
      "isSource": true,
      "original": {
        "category": "color",
        "exportKey": "color",
        "comment": "Use this for texts with high emphasis and active icons.",
        "value": "rgba(17, 24, 39, 1)",
        "type": "color"
      },
      "name": "ColorTextPrimary",
      "attributes": {
        "category": "color",
        "type": "textPrimary",
        "hex": "111827",
        "rgb": {
          "r": 17,
          "g": 24,
          "b": 39,
          "a": 1
        },
        "hsl": {
          "h": 220.9090909090909,
          "s": 0.3928571428571429,
          "l": 0.10980392156862745,
          "a": 1
        },
        "hsv": {
          "h": 220.9090909090909,
          "s": 0.5641025641025641,
          "v": 0.15294117647058825,
          "a": 1
        }
      },
      "path": [
        "color",
        "textPrimary"
      ]
    },
    "textSecondary": {
      "category": "color",
      "exportKey": "color",
      "comment": "Use this for texts with medium emphasis and inactive icons.",
      "value": "#6b7280",
      "type": "color",
      "filePath": "properties/design-tokens.json",
      "isSource": true,
      "original": {
        "category": "color",
        "exportKey": "color",
        "comment": "Use this for texts with medium emphasis and inactive icons.",
        "value": "rgba(107, 114, 128, 1)",
        "type": "color"
      },
      "name": "ColorTextSecondary",
      "attributes": {
        "category": "color",
        "type": "textSecondary",
        "hex": "6b7280",
        "rgb": {
          "r": 107,
          "g": 114,
          "b": 128,
          "a": 1
        },
        "hsl": {
          "h": 219.99999999999997,
          "s": 0.08936170212765955,
          "l": 0.4607843137254902,
          "a": 1
        },
        "hsv": {
          "h": 219.99999999999997,
          "s": 0.16406249999999997,
          "v": 0.5019607843137255,
          "a": 1
        }
      },
      "path": [
        "color",
        "textSecondary"
      ]
    },
    "textDisabled": {
      "category": "color",
      "exportKey": "color",
      "comment": "Use this for disabled texts and icons.",
      "value": "#9ca3af",
      "type": "color",
      "filePath": "properties/design-tokens.json",
      "isSource": true,
      "original": {
        "category": "color",
        "exportKey": "color",
        "comment": "Use this for disabled texts and icons.",
        "value": "rgba(156, 163, 175, 1)",
        "type": "color"
      },
      "name": "ColorTextDisabled",
      "attributes": {
        "category": "color",
        "type": "textDisabled",
        "hex": "9ca3af",
        "rgb": {
          "r": 156,
          "g": 163,
          "b": 175,
          "a": 1
        },
        "hsl": {
          "h": 217.89473684210535,
          "s": 0.10614525139664799,
          "l": 0.6490196078431373,
          "a": 1
        },
        "hsv": {
          "h": 217.89473684210535,
          "s": 0.10857142857142851,
          "v": 0.6862745098039216,
          "a": 1
        }
      },
      "path": [
        "color",
        "textDisabled"
      ]
    },
    "backgroundPaper": {
      "category": "color",
      "exportKey": "color",
      "comment": "Use this as Background for content elements that should separate from default grey background.",
      "value": "#ffffff",
      "type": "color",
      "filePath": "properties/design-tokens.json",
      "isSource": true,
      "original": {
        "category": "color",
        "exportKey": "color",
        "comment": "Use this as Background for content elements that should separate from default grey background.",
        "value": "rgba(255, 255, 255, 1)",
        "type": "color"
      },
      "name": "ColorBackgroundPaper",
      "attributes": {
        "category": "color",
        "type": "backgroundPaper",
        "hex": "ffffff",
        "rgb": {
          "r": 255,
          "g": 255,
          "b": 255,
          "a": 1
        },
        "hsl": {
          "h": 0,
          "s": 0,
          "l": 1,
          "a": 1
        },
        "hsv": {
          "h": 0,
          "s": 0,
          "v": 1,
          "a": 1
        }
      },
      "path": [
        "color",
        "backgroundPaper"
      ]
    },
    "backgroundDefault": {
      "category": "color",
      "exportKey": "color",
      "comment": "Use this as default background.",
      "value": "#f7f7f9",
      "type": "color",
      "filePath": "properties/design-tokens.json",
      "isSource": true,
      "original": {
        "category": "color",
        "exportKey": "color",
        "comment": "Use this as default background.",
        "value": "rgba(247, 247, 249, 1)",
        "type": "color"
      },
      "name": "ColorBackgroundDefault",
      "attributes": {
        "category": "color",
        "type": "backgroundDefault",
        "hex": "f7f7f9",
        "rgb": {
          "r": 247,
          "g": 247,
          "b": 249,
          "a": 1
        },
        "hsl": {
          "h": 240,
          "s": 0.14285714285714257,
          "l": 0.9725490196078431,
          "a": 1
        },
        "hsv": {
          "h": 240,
          "s": 0.008032128514056196,
          "v": 0.9764705882352941,
          "a": 1
        }
      },
      "path": [
        "color",
        "backgroundDefault"
      ]
    },
    "backgroundPage": {
      "category": "color",
      "exportKey": "color",
      "value": "#e5e7eb",
      "type": "color",
      "filePath": "properties/design-tokens.json",
      "isSource": true,
      "original": {
        "category": "color",
        "exportKey": "color",
        "value": "rgba(229, 231, 235, 1)",
        "type": "color"
      },
      "name": "ColorBackgroundPage",
      "attributes": {
        "category": "color",
        "type": "backgroundPage",
        "hex": "e5e7eb",
        "rgb": {
          "r": 229,
          "g": 231,
          "b": 235,
          "a": 1
        },
        "hsl": {
          "h": 219.99999999999997,
          "s": 0.1304347826086951,
          "l": 0.9098039215686274,
          "a": 1
        },
        "hsv": {
          "h": 219.99999999999997,
          "s": 0.025531914893616933,
          "v": 0.9215686274509803,
          "a": 1
        }
      },
      "path": [
        "color",
        "backgroundPage"
      ]
    },
    "infoMain": {
      "category": "color",
      "exportKey": "color",
      "comment": "Use this to present information to the user that is neutral and not necessarily important.",
      "value": "#2196f3",
      "type": "color",
      "filePath": "properties/design-tokens.json",
      "isSource": true,
      "original": {
        "category": "color",
        "exportKey": "color",
        "comment": "Use this to present information to the user that is neutral and not necessarily important.",
        "value": "rgba(33, 150, 243, 1)",
        "type": "color"
      },
      "name": "ColorInfoMain",
      "attributes": {
        "category": "color",
        "type": "infoMain",
        "hex": "2196f3",
        "rgb": {
          "r": 33,
          "g": 150,
          "b": 243,
          "a": 1
        },
        "hsl": {
          "h": 206.57142857142858,
          "s": 0.8974358974358974,
          "l": 0.5411764705882353,
          "a": 1
        },
        "hsv": {
          "h": 206.57142857142858,
          "s": 0.8641975308641976,
          "v": 0.9529411764705882,
          "a": 1
        }
      },
      "path": [
        "color",
        "infoMain"
      ]
    },
    "infoLight": {
      "category": "color",
      "exportKey": "color",
      "comment": "Use this as light version of the info color (may be calculated).",
      "value": "#64b5f6",
      "type": "color",
      "filePath": "properties/design-tokens.json",
      "isSource": true,
      "original": {
        "category": "color",
        "exportKey": "color",
        "comment": "Use this as light version of the info color (may be calculated).",
        "value": "rgba(100, 181, 246, 1)",
        "type": "color"
      },
      "name": "ColorInfoLight",
      "attributes": {
        "category": "color",
        "type": "infoLight",
        "hex": "64b5f6",
        "rgb": {
          "r": 100,
          "g": 181,
          "b": 246,
          "a": 1
        },
        "hsl": {
          "h": 206.71232876712327,
          "s": 0.8902439024390244,
          "l": 0.6784313725490196,
          "a": 1
        },
        "hsv": {
          "h": 206.71232876712327,
          "s": 0.5934959349593496,
          "v": 0.9647058823529412,
          "a": 1
        }
      },
      "path": [
        "color",
        "infoLight"
      ]
    },
    "infoDark": {
      "category": "color",
      "exportKey": "color",
      "value": "#1976d2",
      "type": "color",
      "filePath": "properties/design-tokens.json",
      "isSource": true,
      "original": {
        "category": "color",
        "exportKey": "color",
        "value": "rgba(25, 118, 210, 1)",
        "type": "color"
      },
      "name": "ColorInfoDark",
      "attributes": {
        "category": "color",
        "type": "infoDark",
        "hex": "1976d2",
        "rgb": {
          "r": 25,
          "g": 118,
          "b": 210,
          "a": 1
        },
        "hsl": {
          "h": 209.8378378378378,
          "s": 0.7872340425531915,
          "l": 0.46078431372549017,
          "a": 1
        },
        "hsv": {
          "h": 209.8378378378378,
          "s": 0.8809523809523809,
          "v": 0.8235294117647058,
          "a": 1
        }
      },
      "path": [
        "color",
        "infoDark"
      ]
    },
    "errorMain": {
      "category": "color",
      "exportKey": "color",
      "comment": "Use this to indicate errors in components, such as invalid text in a text field hat the user should be made aware of.",
      "value": "#f44336",
      "type": "color",
      "filePath": "properties/design-tokens.json",
      "isSource": true,
      "original": {
        "category": "color",
        "exportKey": "color",
        "comment": "Use this to indicate errors in components, such as invalid text in a text field hat the user should be made aware of.",
        "value": "rgba(244, 67, 54, 1)",
        "type": "color"
      },
      "name": "ColorErrorMain",
      "attributes": {
        "category": "color",
        "type": "errorMain",
        "hex": "f44336",
        "rgb": {
          "r": 244,
          "g": 67,
          "b": 54,
          "a": 1
        },
        "hsl": {
          "h": 4.105263157894738,
          "s": 0.8962264150943399,
          "l": 0.5843137254901961,
          "a": 1
        },
        "hsv": {
          "h": 4.105263157894738,
          "s": 0.7786885245901639,
          "v": 0.9568627450980393,
          "a": 1
        }
      },
      "path": [
        "color",
        "errorMain"
      ]
    },
    "errorDark": {
      "category": "color",
      "exportKey": "color",
      "comment": "Use this as dark version of the error color (may be calculated).",
      "value": "#d32f2f",
      "type": "color",
      "filePath": "properties/design-tokens.json",
      "isSource": true,
      "original": {
        "category": "color",
        "exportKey": "color",
        "comment": "Use this as dark version of the error color (may be calculated).",
        "value": "rgba(211, 47, 47, 1)",
        "type": "color"
      },
      "name": "ColorErrorDark",
      "attributes": {
        "category": "color",
        "type": "errorDark",
        "hex": "d32f2f",
        "rgb": {
          "r": 211,
          "g": 47,
          "b": 47,
          "a": 1
        },
        "hsl": {
          "h": 0,
          "s": 0.6507936507936507,
          "l": 0.5058823529411764,
          "a": 1
        },
        "hsv": {
          "h": 0,
          "s": 0.7772511848341231,
          "v": 0.8274509803921568,
          "a": 1
        }
      },
      "path": [
        "color",
        "errorDark"
      ]
    },
    "errorLight": {
      "category": "color",
      "exportKey": "color",
      "comment": "Use this as light version of the error color (may be calculated).",
      "value": "#e57373",
      "type": "color",
      "filePath": "properties/design-tokens.json",
      "isSource": true,
      "original": {
        "category": "color",
        "exportKey": "color",
        "comment": "Use this as light version of the error color (may be calculated).",
        "value": "rgba(229, 115, 115, 1)",
        "type": "color"
      },
      "name": "ColorErrorLight",
      "attributes": {
        "category": "color",
        "type": "errorLight",
        "hex": "e57373",
        "rgb": {
          "r": 229,
          "g": 115,
          "b": 115,
          "a": 1
        },
        "hsl": {
          "h": 0,
          "s": 0.6867469879518071,
          "l": 0.6745098039215687,
          "a": 1
        },
        "hsv": {
          "h": 0,
          "s": 0.4978165938864629,
          "v": 0.8980392156862745,
          "a": 1
        }
      },
      "path": [
        "color",
        "errorLight"
      ]
    },
    "successMain": {
      "category": "color",
      "exportKey": "color",
      "comment": "Use this to indicate the successful completion of an action that user triggered.",
      "value": "#4caf50",
      "type": "color",
      "filePath": "properties/design-tokens.json",
      "isSource": true,
      "original": {
        "category": "color",
        "exportKey": "color",
        "comment": "Use this to indicate the successful completion of an action that user triggered.",
        "value": "rgba(76, 175, 80, 1)",
        "type": "color"
      },
      "name": "ColorSuccessMain",
      "attributes": {
        "category": "color",
        "type": "successMain",
        "hex": "4caf50",
        "rgb": {
          "r": 76,
          "g": 175,
          "b": 80,
          "a": 1
        },
        "hsl": {
          "h": 122.42424242424241,
          "s": 0.3944223107569721,
          "l": 0.49215686274509807,
          "a": 1
        },
        "hsv": {
          "h": 122.42424242424241,
          "s": 0.5657142857142857,
          "v": 0.6862745098039216,
          "a": 1
        }
      },
      "path": [
        "color",
        "successMain"
      ]
    },
    "successLight": {
      "category": "color",
      "exportKey": "color",
      "comment": "Use this as light version of the success color (may be calculated).",
      "value": "#81c784",
      "type": "color",
      "filePath": "properties/design-tokens.json",
      "isSource": true,
      "original": {
        "category": "color",
        "exportKey": "color",
        "comment": "Use this as light version of the success color (may be calculated).",
        "value": "rgba(129, 199, 132, 1)",
        "type": "color"
      },
      "name": "ColorSuccessLight",
      "attributes": {
        "category": "color",
        "type": "successLight",
        "hex": "81c784",
        "rgb": {
          "r": 129,
          "g": 199,
          "b": 132,
          "a": 1
        },
        "hsl": {
          "h": 122.5714285714286,
          "s": 0.3846153846153846,
          "l": 0.6431372549019607,
          "a": 1
        },
        "hsv": {
          "h": 122.5714285714286,
          "s": 0.35175879396984927,
          "v": 0.7803921568627451,
          "a": 1
        }
      },
      "path": [
        "color",
        "successLight"
      ]
    },
    "successDark": {
      "category": "color",
      "exportKey": "color",
      "comment": "Use this as dark version of the success color (may be calculated).",
      "value": "#388e3c",
      "type": "color",
      "filePath": "properties/design-tokens.json",
      "isSource": true,
      "original": {
        "category": "color",
        "exportKey": "color",
        "comment": "Use this as dark version of the success color (may be calculated).",
        "value": "rgba(56, 142, 60, 1)",
        "type": "color"
      },
      "name": "ColorSuccessDark",
      "attributes": {
        "category": "color",
        "type": "successDark",
        "hex": "388e3c",
        "rgb": {
          "r": 56,
          "g": 142,
          "b": 60,
          "a": 1
        },
        "hsl": {
          "h": 122.79069767441861,
          "s": 0.43434343434343436,
          "l": 0.38823529411764707,
          "a": 1
        },
        "hsv": {
          "h": 122.79069767441861,
          "s": 0.6056338028169014,
          "v": 0.5568627450980392,
          "a": 1
        }
      },
      "path": [
        "color",
        "successDark"
      ]
    },
    "warningMain": {
      "category": "color",
      "exportKey": "color",
      "comment": "Use this for important messages and notifications to represent potentially dangerous actions.",
      "value": "#ff9800",
      "type": "color",
      "filePath": "properties/design-tokens.json",
      "isSource": true,
      "original": {
        "category": "color",
        "exportKey": "color",
        "comment": "Use this for important messages and notifications to represent potentially dangerous actions.",
        "value": "rgba(255, 152, 0, 1)",
        "type": "color"
      },
      "name": "ColorWarningMain",
      "attributes": {
        "category": "color",
        "type": "warningMain",
        "hex": "ff9800",
        "rgb": {
          "r": 255,
          "g": 152,
          "b": 0,
          "a": 1
        },
        "hsl": {
          "h": 35.76470588235294,
          "s": 1,
          "l": 0.5,
          "a": 1
        },
        "hsv": {
          "h": 35.76470588235294,
          "s": 1,
          "v": 1,
          "a": 1
        }
      },
      "path": [
        "color",
        "warningMain"
      ]
    },
    "warningLight": {
      "category": "color",
      "exportKey": "color",
      "comment": "Use this as light version of the warning color (may be calculated).",
      "value": "#ffb74d",
      "type": "color",
      "filePath": "properties/design-tokens.json",
      "isSource": true,
      "original": {
        "category": "color",
        "exportKey": "color",
        "comment": "Use this as light version of the warning color (may be calculated).",
        "value": "rgba(255, 183, 77, 1)",
        "type": "color"
      },
      "name": "ColorWarningLight",
      "attributes": {
        "category": "color",
        "type": "warningLight",
        "hex": "ffb74d",
        "rgb": {
          "r": 255,
          "g": 183,
          "b": 77,
          "a": 1
        },
        "hsl": {
          "h": 35.73033707865169,
          "s": 1,
          "l": 0.6509803921568628,
          "a": 1
        },
        "hsv": {
          "h": 35.73033707865169,
          "s": 0.6980392156862745,
          "v": 1,
          "a": 1
        }
      },
      "path": [
        "color",
        "warningLight"
      ]
    },
    "warningDark": {
      "category": "color",
      "exportKey": "color",
      "comment": "Use this as dark version of the warning color (may be calculated).",
      "value": "#f57c00",
      "type": "color",
      "filePath": "properties/design-tokens.json",
      "isSource": true,
      "original": {
        "category": "color",
        "exportKey": "color",
        "comment": "Use this as dark version of the warning color (may be calculated).",
        "value": "rgba(245, 124, 0, 1)",
        "type": "color"
      },
      "name": "ColorWarningDark",
      "attributes": {
        "category": "color",
        "type": "warningDark",
        "hex": "f57c00",
        "rgb": {
          "r": 245,
          "g": 124,
          "b": 0,
          "a": 1
        },
        "hsl": {
          "h": 30.36734693877551,
          "s": 1,
          "l": 0.4803921568627451,
          "a": 1
        },
        "hsv": {
          "h": 30.36734693877551,
          "s": 1,
          "v": 0.9607843137254902,
          "a": 1
        }
      },
      "path": [
        "color",
        "warningDark"
      ]
    },
    "divider": {
      "category": "color",
      "exportKey": "color",
      "comment": "Use this for thin lines.",
      "value": "rgba(17, 24, 39, 0.12)",
      "type": "color",
      "filePath": "properties/design-tokens.json",
      "isSource": true,
      "original": {
        "category": "color",
        "exportKey": "color",
        "comment": "Use this for thin lines.",
        "value": "rgba(17, 24, 39, 0.12)",
        "type": "color"
      },
      "name": "ColorDivider",
      "attributes": {
        "category": "color",
        "type": "divider",
        "hex": "111827",
        "rgb": {
          "r": 17,
          "g": 24,
          "b": 39,
          "a": 0.12
        },
        "hsl": {
          "h": 220.9090909090909,
          "s": 0.3928571428571429,
          "l": 0.10980392156862745,
          "a": 0.12
        },
        "hsv": {
          "h": 220.9090909090909,
          "s": 0.5641025641025641,
          "v": 0.15294117647058825,
          "a": 0.12
        }
      },
      "path": [
        "color",
        "divider"
      ]
    },
    "dividerDarkTheme": {
      "category": "color",
      "exportKey": "color",
      "value": "rgba(255, 255, 255, 0.15)",
      "type": "color",
      "filePath": "properties/design-tokens.json",
      "isSource": true,
      "original": {
        "category": "color",
        "exportKey": "color",
        "value": "rgba(255, 255, 255, 0.15)",
        "type": "color"
      },
      "name": "ColorDividerDarkTheme",
      "attributes": {
        "category": "color",
        "type": "dividerDarkTheme",
        "hex": "ffffff",
        "rgb": {
          "r": 255,
          "g": 255,
          "b": 255,
          "a": 0.15
        },
        "hsl": {
          "h": 0,
          "s": 0,
          "l": 1,
          "a": 0.15
        },
        "hsv": {
          "h": 0,
          "s": 0,
          "v": 1,
          "a": 0.15
        }
      },
      "path": [
        "color",
        "dividerDarkTheme"
      ]
    },
    "textPrimaryDarkTheme": {
      "category": "color",
      "exportKey": "color",
      "value": "#ffffff",
      "type": "color",
      "filePath": "properties/design-tokens.json",
      "isSource": true,
      "original": {
        "category": "color",
        "exportKey": "color",
        "value": "rgba(255, 255, 255, 1)",
        "type": "color"
      },
      "name": "ColorTextPrimaryDarkTheme",
      "attributes": {
        "category": "color",
        "type": "textPrimaryDarkTheme",
        "hex": "ffffff",
        "rgb": {
          "r": 255,
          "g": 255,
          "b": 255,
          "a": 1
        },
        "hsl": {
          "h": 0,
          "s": 0,
          "l": 1,
          "a": 1
        },
        "hsv": {
          "h": 0,
          "s": 0,
          "v": 1,
          "a": 1
        }
      },
      "path": [
        "color",
        "textPrimaryDarkTheme"
      ]
    },
    "textSecondaryDarkTheme": {
      "category": "color",
      "exportKey": "color",
      "value": "#9ca3af",
      "type": "color",
      "filePath": "properties/design-tokens.json",
      "isSource": true,
      "original": {
        "category": "color",
        "exportKey": "color",
        "value": "rgba(156, 163, 175, 1)",
        "type": "color"
      },
      "name": "ColorTextSecondaryDarkTheme",
      "attributes": {
        "category": "color",
        "type": "textSecondaryDarkTheme",
        "hex": "9ca3af",
        "rgb": {
          "r": 156,
          "g": 163,
          "b": 175,
          "a": 1
        },
        "hsl": {
          "h": 217.89473684210535,
          "s": 0.10614525139664799,
          "l": 0.6490196078431373,
          "a": 1
        },
        "hsv": {
          "h": 217.89473684210535,
          "s": 0.10857142857142851,
          "v": 0.6862745098039216,
          "a": 1
        }
      },
      "path": [
        "color",
        "textSecondaryDarkTheme"
      ]
    },
    "backgroundPaperDarkTheme": {
      "category": "color",
      "exportKey": "color",
      "value": "#1f2937",
      "type": "color",
      "filePath": "properties/design-tokens.json",
      "isSource": true,
      "original": {
        "category": "color",
        "exportKey": "color",
        "value": "rgba(31, 41, 55, 1)",
        "type": "color"
      },
      "name": "ColorBackgroundPaperDarkTheme",
      "attributes": {
        "category": "color",
        "type": "backgroundPaperDarkTheme",
        "hex": "1f2937",
        "rgb": {
          "r": 31,
          "g": 41,
          "b": 55,
          "a": 1
        },
        "hsl": {
          "h": 215,
          "s": 0.27906976744186046,
          "l": 0.16862745098039217,
          "a": 1
        },
        "hsv": {
          "h": 215,
          "s": 0.4363636363636364,
          "v": 0.21568627450980393,
          "a": 1
        }
      },
      "path": [
        "color",
        "backgroundPaperDarkTheme"
      ]
    },
    "backgroundDefaultDarkTheme": {
      "category": "color",
      "exportKey": "color",
      "value": "#111827",
      "type": "color",
      "filePath": "properties/design-tokens.json",
      "isSource": true,
      "original": {
        "category": "color",
        "exportKey": "color",
        "value": "rgba(17, 24, 39, 1)",
        "type": "color"
      },
      "name": "ColorBackgroundDefaultDarkTheme",
      "attributes": {
        "category": "color",
        "type": "backgroundDefaultDarkTheme",
        "hex": "111827",
        "rgb": {
          "r": 17,
          "g": 24,
          "b": 39,
          "a": 1
        },
        "hsl": {
          "h": 220.9090909090909,
          "s": 0.3928571428571429,
          "l": 0.10980392156862745,
          "a": 1
        },
        "hsv": {
          "h": 220.9090909090909,
          "s": 0.5641025641025641,
          "v": 0.15294117647058825,
          "a": 1
        }
      },
      "path": [
        "color",
        "backgroundDefaultDarkTheme"
      ]
    },
    "backgroundDefaultArticles": {
      "category": "color",
      "exportKey": "color",
      "value": "#f5f5f5",
      "type": "color",
      "filePath": "properties/design-tokens.json",
      "isSource": true,
      "original": {
        "category": "color",
        "exportKey": "color",
        "value": "rgba(245, 245, 245, 1)",
        "type": "color"
      },
      "name": "ColorBackgroundDefaultArticles",
      "attributes": {
        "category": "color",
        "type": "backgroundDefaultArticles",
        "hex": "f5f5f5",
        "rgb": {
          "r": 245,
          "g": 245,
          "b": 245,
          "a": 1
        },
        "hsl": {
          "h": 0,
          "s": 0,
          "l": 0.9607843137254902,
          "a": 1
        },
        "hsv": {
          "h": 0,
          "s": 0,
          "v": 0.9607843137254902,
          "a": 1
        }
      },
      "path": [
        "color",
        "backgroundDefaultArticles"
      ]
    },
    "textDisabledDarkTheme": {
      "category": "color",
      "exportKey": "color",
      "value": "#4b5563",
      "type": "color",
      "filePath": "properties/design-tokens.json",
      "isSource": true,
      "original": {
        "category": "color",
        "exportKey": "color",
        "value": "rgba(75, 85, 99, 1)",
        "type": "color"
      },
      "name": "ColorTextDisabledDarkTheme",
      "attributes": {
        "category": "color",
        "type": "textDisabledDarkTheme",
        "hex": "4b5563",
        "rgb": {
          "r": 75,
          "g": 85,
          "b": 99,
          "a": 1
        },
        "hsl": {
          "h": 215,
          "s": 0.13793103448275862,
          "l": 0.3411764705882353,
          "a": 1
        },
        "hsv": {
          "h": 215,
          "s": 0.2424242424242424,
          "v": 0.38823529411764707,
          "a": 1
        }
      },
      "path": [
        "color",
        "textDisabledDarkTheme"
      ]
    },
    "twitter": {
      "category": "color",
      "exportKey": "color",
      "value": "#1da1f2",
      "type": "color",
      "filePath": "properties/design-tokens.json",
      "isSource": true,
      "original": {
        "category": "color",
        "exportKey": "color",
        "value": "rgba(29, 161, 242, 1)",
        "type": "color"
      },
      "name": "ColorTwitter",
      "attributes": {
        "category": "color",
        "type": "twitter",
        "hex": "1da1f2",
        "rgb": {
          "r": 29,
          "g": 161,
          "b": 242,
          "a": 1
        },
        "hsl": {
          "h": 202.81690140845072,
          "s": 0.8912133891213389,
          "l": 0.5313725490196078,
          "a": 1
        },
        "hsv": {
          "h": 202.81690140845072,
          "s": 0.8801652892561983,
          "v": 0.9490196078431372,
          "a": 1
        }
      },
      "path": [
        "color",
        "twitter"
      ]
    },
    "youtube": {
      "category": "color",
      "exportKey": "color",
      "value": "#ff0000",
      "type": "color",
      "filePath": "properties/design-tokens.json",
      "isSource": true,
      "original": {
        "category": "color",
        "exportKey": "color",
        "value": "rgba(255, 0, 0, 1)",
        "type": "color"
      },
      "name": "ColorYoutube",
      "attributes": {
        "category": "color",
        "type": "youtube",
        "hex": "ff0000",
        "rgb": {
          "r": 255,
          "g": 0,
          "b": 0,
          "a": 1
        },
        "hsl": {
          "h": 0,
          "s": 1,
          "l": 0.5,
          "a": 1
        },
        "hsv": {
          "h": 0,
          "s": 1,
          "v": 1,
          "a": 1
        }
      },
      "path": [
        "color",
        "youtube"
      ]
    },
    "instagram": {
      "category": "color",
      "exportKey": "color",
      "value": "#ff0069",
      "type": "color",
      "filePath": "properties/design-tokens.json",
      "isSource": true,
      "original": {
        "category": "color",
        "exportKey": "color",
        "value": "rgba(255, 0, 105, 1)",
        "type": "color"
      },
      "name": "ColorInstagram",
      "attributes": {
        "category": "color",
        "type": "instagram",
        "hex": "ff0069",
        "rgb": {
          "r": 255,
          "g": 0,
          "b": 105,
          "a": 1
        },
        "hsl": {
          "h": 335.2941176470588,
          "s": 1,
          "l": 0.5,
          "a": 1
        },
        "hsv": {
          "h": 335.2941176470588,
          "s": 1,
          "v": 1,
          "a": 1
        }
      },
      "path": [
        "color",
        "instagram"
      ]
    },
    "facebook": {
      "category": "color",
      "exportKey": "color",
      "value": "#1877f2",
      "type": "color",
      "filePath": "properties/design-tokens.json",
      "isSource": true,
      "original": {
        "category": "color",
        "exportKey": "color",
        "value": "rgba(24, 119, 242, 1)",
        "type": "color"
      },
      "name": "ColorFacebook",
      "attributes": {
        "category": "color",
        "type": "facebook",
        "hex": "1877f2",
        "rgb": {
          "r": 24,
          "g": 119,
          "b": 242,
          "a": 1
        },
        "hsl": {
          "h": 213.8532110091743,
          "s": 0.8934426229508196,
          "l": 0.5215686274509803,
          "a": 1
        },
        "hsv": {
          "h": 213.8532110091743,
          "s": 0.9008264462809917,
          "v": 0.9490196078431372,
          "a": 1
        }
      },
      "path": [
        "color",
        "facebook"
      ]
    }
  },
  "font": {
    "h1": {
      "category": "font",
      "exportKey": "font",
      "fontSize": {
        "value": "56px",
        "type": "number",
        "unit": "pixel",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 56,
          "type": "number",
          "unit": "pixel"
        },
        "name": "FontH1FontSize",
        "attributes": {
          "category": "font",
          "type": "h1",
          "item": "fontSize"
        },
        "path": [
          "font",
          "h1",
          "fontSize"
        ]
      },
      "textDecoration": {
        "value": "none",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "none",
          "type": "string"
        },
        "name": "FontH1TextDecoration",
        "attributes": {
          "category": "font",
          "type": "h1",
          "item": "textDecoration"
        },
        "path": [
          "font",
          "h1",
          "textDecoration"
        ]
      },
      "fontFamily": {
        "value": "Inter",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "Inter",
          "type": "string"
        },
        "name": "FontH1FontFamily",
        "attributes": {
          "category": "font",
          "type": "h1",
          "item": "fontFamily"
        },
        "path": [
          "font",
          "h1",
          "fontFamily"
        ]
      },
      "fontWeight": {
        "value": 300,
        "type": "number",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 300,
          "type": "number"
        },
        "name": "FontH1FontWeight",
        "attributes": {
          "category": "font",
          "type": "h1",
          "item": "fontWeight"
        },
        "path": [
          "font",
          "h1",
          "fontWeight"
        ]
      },
      "fontStyle": {
        "value": "normal",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "normal",
          "type": "string"
        },
        "name": "FontH1FontStyle",
        "attributes": {
          "category": "font",
          "type": "h1",
          "item": "fontStyle"
        },
        "path": [
          "font",
          "h1",
          "fontStyle"
        ]
      },
      "fontStretch": {
        "value": "normal",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "normal",
          "type": "string"
        },
        "name": "FontH1FontStretch",
        "attributes": {
          "category": "font",
          "type": "h1",
          "item": "fontStretch"
        },
        "path": [
          "font",
          "h1",
          "fontStretch"
        ]
      },
      "fontStyleOld": {
        "value": "Light",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "Light",
          "type": "string"
        },
        "name": "FontH1FontStyleOld",
        "attributes": {
          "category": "font",
          "type": "h1",
          "item": "fontStyleOld"
        },
        "path": [
          "font",
          "h1",
          "fontStyleOld"
        ]
      },
      "letterSpacing": {
        "value": "-3%",
        "type": "number",
        "unit": "percent",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": -3,
          "type": "number",
          "unit": "percent"
        },
        "name": "FontH1LetterSpacing",
        "attributes": {
          "category": "font",
          "type": "h1",
          "item": "letterSpacing",
          "em": "-0.03em"
        },
        "path": [
          "font",
          "h1",
          "letterSpacing"
        ]
      },
      "lineHeight": {
        "value": "110%",
        "type": "number",
        "unit": "percent",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 110,
          "type": "number",
          "unit": "percent"
        },
        "name": "FontH1LineHeight",
        "attributes": {
          "category": "font",
          "type": "h1",
          "item": "lineHeight",
          "em": "1.1em"
        },
        "path": [
          "font",
          "h1",
          "lineHeight"
        ]
      },
      "paragraphIndent": {
        "value": "0px",
        "type": "number",
        "unit": "pixel",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 0,
          "type": "number",
          "unit": "pixel"
        },
        "name": "FontH1ParagraphIndent",
        "attributes": {
          "category": "font",
          "type": "h1",
          "item": "paragraphIndent"
        },
        "path": [
          "font",
          "h1",
          "paragraphIndent"
        ]
      },
      "paragraphSpacing": {
        "value": "0px",
        "type": "number",
        "unit": "pixel",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 0,
          "type": "number",
          "unit": "pixel"
        },
        "name": "FontH1ParagraphSpacing",
        "attributes": {
          "category": "font",
          "type": "h1",
          "item": "paragraphSpacing"
        },
        "path": [
          "font",
          "h1",
          "paragraphSpacing"
        ]
      },
      "textCase": {
        "value": "none",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "none",
          "type": "string"
        },
        "name": "FontH1TextCase",
        "attributes": {
          "category": "font",
          "type": "h1",
          "item": "textCase"
        },
        "path": [
          "font",
          "h1",
          "textCase"
        ]
      }
    },
    "h2": {
      "category": "font",
      "exportKey": "font",
      "fontSize": {
        "value": "38px",
        "type": "number",
        "unit": "pixel",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 38,
          "type": "number",
          "unit": "pixel"
        },
        "name": "FontH2FontSize",
        "attributes": {
          "category": "font",
          "type": "h2",
          "item": "fontSize"
        },
        "path": [
          "font",
          "h2",
          "fontSize"
        ]
      },
      "textDecoration": {
        "value": "none",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "none",
          "type": "string"
        },
        "name": "FontH2TextDecoration",
        "attributes": {
          "category": "font",
          "type": "h2",
          "item": "textDecoration"
        },
        "path": [
          "font",
          "h2",
          "textDecoration"
        ]
      },
      "fontFamily": {
        "value": "Antonio",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "Antonio",
          "type": "string"
        },
        "name": "FontH2FontFamily",
        "attributes": {
          "category": "font",
          "type": "h2",
          "item": "fontFamily"
        },
        "path": [
          "font",
          "h2",
          "fontFamily"
        ]
      },
      "fontWeight": {
        "value": 300,
        "type": "number",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 300,
          "type": "number"
        },
        "name": "FontH2FontWeight",
        "attributes": {
          "category": "font",
          "type": "h2",
          "item": "fontWeight"
        },
        "path": [
          "font",
          "h2",
          "fontWeight"
        ]
      },
      "fontStyle": {
        "value": "normal",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "normal",
          "type": "string"
        },
        "name": "FontH2FontStyle",
        "attributes": {
          "category": "font",
          "type": "h2",
          "item": "fontStyle"
        },
        "path": [
          "font",
          "h2",
          "fontStyle"
        ]
      },
      "fontStretch": {
        "value": "normal",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "normal",
          "type": "string"
        },
        "name": "FontH2FontStretch",
        "attributes": {
          "category": "font",
          "type": "h2",
          "item": "fontStretch"
        },
        "path": [
          "font",
          "h2",
          "fontStretch"
        ]
      },
      "fontStyleOld": {
        "value": "Light",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "Light",
          "type": "string"
        },
        "name": "FontH2FontStyleOld",
        "attributes": {
          "category": "font",
          "type": "h2",
          "item": "fontStyleOld"
        },
        "path": [
          "font",
          "h2",
          "fontStyleOld"
        ]
      },
      "letterSpacing": {
        "value": "-3%",
        "type": "number",
        "unit": "percent",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": -3,
          "type": "number",
          "unit": "percent"
        },
        "name": "FontH2LetterSpacing",
        "attributes": {
          "category": "font",
          "type": "h2",
          "item": "letterSpacing",
          "em": "-0.03em"
        },
        "path": [
          "font",
          "h2",
          "letterSpacing"
        ]
      },
      "lineHeight": {
        "value": "110%",
        "type": "number",
        "unit": "percent",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 110,
          "type": "number",
          "unit": "percent"
        },
        "name": "FontH2LineHeight",
        "attributes": {
          "category": "font",
          "type": "h2",
          "item": "lineHeight",
          "em": "1.1em"
        },
        "path": [
          "font",
          "h2",
          "lineHeight"
        ]
      },
      "paragraphIndent": {
        "value": "0px",
        "type": "number",
        "unit": "pixel",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 0,
          "type": "number",
          "unit": "pixel"
        },
        "name": "FontH2ParagraphIndent",
        "attributes": {
          "category": "font",
          "type": "h2",
          "item": "paragraphIndent"
        },
        "path": [
          "font",
          "h2",
          "paragraphIndent"
        ]
      },
      "paragraphSpacing": {
        "value": "0px",
        "type": "number",
        "unit": "pixel",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 0,
          "type": "number",
          "unit": "pixel"
        },
        "name": "FontH2ParagraphSpacing",
        "attributes": {
          "category": "font",
          "type": "h2",
          "item": "paragraphSpacing"
        },
        "path": [
          "font",
          "h2",
          "paragraphSpacing"
        ]
      },
      "textCase": {
        "value": "none",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "none",
          "type": "string"
        },
        "name": "FontH2TextCase",
        "attributes": {
          "category": "font",
          "type": "h2",
          "item": "textCase"
        },
        "path": [
          "font",
          "h2",
          "textCase"
        ]
      }
    },
    "h3": {
      "category": "font",
      "exportKey": "font",
      "fontSize": {
        "value": "32px",
        "type": "number",
        "unit": "pixel",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 32,
          "type": "number",
          "unit": "pixel"
        },
        "name": "FontH3FontSize",
        "attributes": {
          "category": "font",
          "type": "h3",
          "item": "fontSize"
        },
        "path": [
          "font",
          "h3",
          "fontSize"
        ]
      },
      "textDecoration": {
        "value": "none",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "none",
          "type": "string"
        },
        "name": "FontH3TextDecoration",
        "attributes": {
          "category": "font",
          "type": "h3",
          "item": "textDecoration"
        },
        "path": [
          "font",
          "h3",
          "textDecoration"
        ]
      },
      "fontFamily": {
        "value": "Inter",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "Inter",
          "type": "string"
        },
        "name": "FontH3FontFamily",
        "attributes": {
          "category": "font",
          "type": "h3",
          "item": "fontFamily"
        },
        "path": [
          "font",
          "h3",
          "fontFamily"
        ]
      },
      "fontWeight": {
        "value": 300,
        "type": "number",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 300,
          "type": "number"
        },
        "name": "FontH3FontWeight",
        "attributes": {
          "category": "font",
          "type": "h3",
          "item": "fontWeight"
        },
        "path": [
          "font",
          "h3",
          "fontWeight"
        ]
      },
      "fontStyle": {
        "value": "normal",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "normal",
          "type": "string"
        },
        "name": "FontH3FontStyle",
        "attributes": {
          "category": "font",
          "type": "h3",
          "item": "fontStyle"
        },
        "path": [
          "font",
          "h3",
          "fontStyle"
        ]
      },
      "fontStretch": {
        "value": "normal",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "normal",
          "type": "string"
        },
        "name": "FontH3FontStretch",
        "attributes": {
          "category": "font",
          "type": "h3",
          "item": "fontStretch"
        },
        "path": [
          "font",
          "h3",
          "fontStretch"
        ]
      },
      "fontStyleOld": {
        "value": "Light",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "Light",
          "type": "string"
        },
        "name": "FontH3FontStyleOld",
        "attributes": {
          "category": "font",
          "type": "h3",
          "item": "fontStyleOld"
        },
        "path": [
          "font",
          "h3",
          "fontStyleOld"
        ]
      },
      "letterSpacing": {
        "value": "-3%",
        "type": "number",
        "unit": "percent",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": -3,
          "type": "number",
          "unit": "percent"
        },
        "name": "FontH3LetterSpacing",
        "attributes": {
          "category": "font",
          "type": "h3",
          "item": "letterSpacing",
          "em": "-0.03em"
        },
        "path": [
          "font",
          "h3",
          "letterSpacing"
        ]
      },
      "lineHeight": {
        "value": "116.7%",
        "type": "number",
        "unit": "percent",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 116.7,
          "type": "number",
          "unit": "percent"
        },
        "name": "FontH3LineHeight",
        "attributes": {
          "category": "font",
          "type": "h3",
          "item": "lineHeight",
          "em": "1.167em"
        },
        "path": [
          "font",
          "h3",
          "lineHeight"
        ]
      },
      "paragraphIndent": {
        "value": "0px",
        "type": "number",
        "unit": "pixel",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 0,
          "type": "number",
          "unit": "pixel"
        },
        "name": "FontH3ParagraphIndent",
        "attributes": {
          "category": "font",
          "type": "h3",
          "item": "paragraphIndent"
        },
        "path": [
          "font",
          "h3",
          "paragraphIndent"
        ]
      },
      "paragraphSpacing": {
        "value": "0px",
        "type": "number",
        "unit": "pixel",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 0,
          "type": "number",
          "unit": "pixel"
        },
        "name": "FontH3ParagraphSpacing",
        "attributes": {
          "category": "font",
          "type": "h3",
          "item": "paragraphSpacing"
        },
        "path": [
          "font",
          "h3",
          "paragraphSpacing"
        ]
      },
      "textCase": {
        "value": "none",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "none",
          "type": "string"
        },
        "name": "FontH3TextCase",
        "attributes": {
          "category": "font",
          "type": "h3",
          "item": "textCase"
        },
        "path": [
          "font",
          "h3",
          "textCase"
        ]
      }
    },
    "h4": {
      "category": "font",
      "exportKey": "font",
      "fontSize": {
        "value": "25px",
        "type": "number",
        "unit": "pixel",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 25,
          "type": "number",
          "unit": "pixel"
        },
        "name": "FontH4FontSize",
        "attributes": {
          "category": "font",
          "type": "h4",
          "item": "fontSize"
        },
        "path": [
          "font",
          "h4",
          "fontSize"
        ]
      },
      "textDecoration": {
        "value": "none",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "none",
          "type": "string"
        },
        "name": "FontH4TextDecoration",
        "attributes": {
          "category": "font",
          "type": "h4",
          "item": "textDecoration"
        },
        "path": [
          "font",
          "h4",
          "textDecoration"
        ]
      },
      "fontFamily": {
        "value": "Antonio",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "Antonio",
          "type": "string"
        },
        "name": "FontH4FontFamily",
        "attributes": {
          "category": "font",
          "type": "h4",
          "item": "fontFamily"
        },
        "path": [
          "font",
          "h4",
          "fontFamily"
        ]
      },
      "fontWeight": {
        "value": 300,
        "type": "number",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 300,
          "type": "number"
        },
        "name": "FontH4FontWeight",
        "attributes": {
          "category": "font",
          "type": "h4",
          "item": "fontWeight"
        },
        "path": [
          "font",
          "h4",
          "fontWeight"
        ]
      },
      "fontStyle": {
        "value": "normal",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "normal",
          "type": "string"
        },
        "name": "FontH4FontStyle",
        "attributes": {
          "category": "font",
          "type": "h4",
          "item": "fontStyle"
        },
        "path": [
          "font",
          "h4",
          "fontStyle"
        ]
      },
      "fontStretch": {
        "value": "normal",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "normal",
          "type": "string"
        },
        "name": "FontH4FontStretch",
        "attributes": {
          "category": "font",
          "type": "h4",
          "item": "fontStretch"
        },
        "path": [
          "font",
          "h4",
          "fontStretch"
        ]
      },
      "fontStyleOld": {
        "value": "Light",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "Light",
          "type": "string"
        },
        "name": "FontH4FontStyleOld",
        "attributes": {
          "category": "font",
          "type": "h4",
          "item": "fontStyleOld"
        },
        "path": [
          "font",
          "h4",
          "fontStyleOld"
        ]
      },
      "letterSpacing": {
        "value": "-2%",
        "type": "number",
        "unit": "percent",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": -2,
          "type": "number",
          "unit": "percent"
        },
        "name": "FontH4LetterSpacing",
        "attributes": {
          "category": "font",
          "type": "h4",
          "item": "letterSpacing",
          "em": "-0.02em"
        },
        "path": [
          "font",
          "h4",
          "letterSpacing"
        ]
      },
      "lineHeight": {
        "value": "123.5%",
        "type": "number",
        "unit": "percent",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 123.5,
          "type": "number",
          "unit": "percent"
        },
        "name": "FontH4LineHeight",
        "attributes": {
          "category": "font",
          "type": "h4",
          "item": "lineHeight",
          "em": "1.235em"
        },
        "path": [
          "font",
          "h4",
          "lineHeight"
        ]
      },
      "paragraphIndent": {
        "value": "0px",
        "type": "number",
        "unit": "pixel",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 0,
          "type": "number",
          "unit": "pixel"
        },
        "name": "FontH4ParagraphIndent",
        "attributes": {
          "category": "font",
          "type": "h4",
          "item": "paragraphIndent"
        },
        "path": [
          "font",
          "h4",
          "paragraphIndent"
        ]
      },
      "paragraphSpacing": {
        "value": "0px",
        "type": "number",
        "unit": "pixel",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 0,
          "type": "number",
          "unit": "pixel"
        },
        "name": "FontH4ParagraphSpacing",
        "attributes": {
          "category": "font",
          "type": "h4",
          "item": "paragraphSpacing"
        },
        "path": [
          "font",
          "h4",
          "paragraphSpacing"
        ]
      },
      "textCase": {
        "value": "none",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "none",
          "type": "string"
        },
        "name": "FontH4TextCase",
        "attributes": {
          "category": "font",
          "type": "h4",
          "item": "textCase"
        },
        "path": [
          "font",
          "h4",
          "textCase"
        ]
      }
    },
    "h5": {
      "category": "font",
      "exportKey": "font",
      "fontSize": {
        "value": "20px",
        "type": "number",
        "unit": "pixel",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 20,
          "type": "number",
          "unit": "pixel"
        },
        "name": "FontH5FontSize",
        "attributes": {
          "category": "font",
          "type": "h5",
          "item": "fontSize"
        },
        "path": [
          "font",
          "h5",
          "fontSize"
        ]
      },
      "textDecoration": {
        "value": "none",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "none",
          "type": "string"
        },
        "name": "FontH5TextDecoration",
        "attributes": {
          "category": "font",
          "type": "h5",
          "item": "textDecoration"
        },
        "path": [
          "font",
          "h5",
          "textDecoration"
        ]
      },
      "fontFamily": {
        "value": "Antonio",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "Antonio",
          "type": "string"
        },
        "name": "FontH5FontFamily",
        "attributes": {
          "category": "font",
          "type": "h5",
          "item": "fontFamily"
        },
        "path": [
          "font",
          "h5",
          "fontFamily"
        ]
      },
      "fontWeight": {
        "value": 300,
        "type": "number",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 300,
          "type": "number"
        },
        "name": "FontH5FontWeight",
        "attributes": {
          "category": "font",
          "type": "h5",
          "item": "fontWeight"
        },
        "path": [
          "font",
          "h5",
          "fontWeight"
        ]
      },
      "fontStyle": {
        "value": "normal",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "normal",
          "type": "string"
        },
        "name": "FontH5FontStyle",
        "attributes": {
          "category": "font",
          "type": "h5",
          "item": "fontStyle"
        },
        "path": [
          "font",
          "h5",
          "fontStyle"
        ]
      },
      "fontStretch": {
        "value": "normal",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "normal",
          "type": "string"
        },
        "name": "FontH5FontStretch",
        "attributes": {
          "category": "font",
          "type": "h5",
          "item": "fontStretch"
        },
        "path": [
          "font",
          "h5",
          "fontStretch"
        ]
      },
      "fontStyleOld": {
        "value": "Light",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "Light",
          "type": "string"
        },
        "name": "FontH5FontStyleOld",
        "attributes": {
          "category": "font",
          "type": "h5",
          "item": "fontStyleOld"
        },
        "path": [
          "font",
          "h5",
          "fontStyleOld"
        ]
      },
      "letterSpacing": {
        "value": "-3%",
        "type": "number",
        "unit": "percent",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": -3,
          "type": "number",
          "unit": "percent"
        },
        "name": "FontH5LetterSpacing",
        "attributes": {
          "category": "font",
          "type": "h5",
          "item": "letterSpacing",
          "em": "-0.03em"
        },
        "path": [
          "font",
          "h5",
          "letterSpacing"
        ]
      },
      "lineHeight": {
        "value": "115%",
        "type": "number",
        "unit": "percent",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 115,
          "type": "number",
          "unit": "percent"
        },
        "name": "FontH5LineHeight",
        "attributes": {
          "category": "font",
          "type": "h5",
          "item": "lineHeight",
          "em": "1.15em"
        },
        "path": [
          "font",
          "h5",
          "lineHeight"
        ]
      },
      "paragraphIndent": {
        "value": "0px",
        "type": "number",
        "unit": "pixel",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 0,
          "type": "number",
          "unit": "pixel"
        },
        "name": "FontH5ParagraphIndent",
        "attributes": {
          "category": "font",
          "type": "h5",
          "item": "paragraphIndent"
        },
        "path": [
          "font",
          "h5",
          "paragraphIndent"
        ]
      },
      "paragraphSpacing": {
        "value": "0px",
        "type": "number",
        "unit": "pixel",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 0,
          "type": "number",
          "unit": "pixel"
        },
        "name": "FontH5ParagraphSpacing",
        "attributes": {
          "category": "font",
          "type": "h5",
          "item": "paragraphSpacing"
        },
        "path": [
          "font",
          "h5",
          "paragraphSpacing"
        ]
      },
      "textCase": {
        "value": "none",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "none",
          "type": "string"
        },
        "name": "FontH5TextCase",
        "attributes": {
          "category": "font",
          "type": "h5",
          "item": "textCase"
        },
        "path": [
          "font",
          "h5",
          "textCase"
        ]
      }
    },
    "h6": {
      "category": "font",
      "exportKey": "font",
      "fontSize": {
        "value": "19px",
        "type": "number",
        "unit": "pixel",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 19,
          "type": "number",
          "unit": "pixel"
        },
        "name": "FontH6FontSize",
        "attributes": {
          "category": "font",
          "type": "h6",
          "item": "fontSize"
        },
        "path": [
          "font",
          "h6",
          "fontSize"
        ]
      },
      "textDecoration": {
        "value": "none",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "none",
          "type": "string"
        },
        "name": "FontH6TextDecoration",
        "attributes": {
          "category": "font",
          "type": "h6",
          "item": "textDecoration"
        },
        "path": [
          "font",
          "h6",
          "textDecoration"
        ]
      },
      "fontFamily": {
        "value": "Antonio",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "Antonio",
          "type": "string"
        },
        "name": "FontH6FontFamily",
        "attributes": {
          "category": "font",
          "type": "h6",
          "item": "fontFamily"
        },
        "path": [
          "font",
          "h6",
          "fontFamily"
        ]
      },
      "fontWeight": {
        "value": 300,
        "type": "number",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 300,
          "type": "number"
        },
        "name": "FontH6FontWeight",
        "attributes": {
          "category": "font",
          "type": "h6",
          "item": "fontWeight"
        },
        "path": [
          "font",
          "h6",
          "fontWeight"
        ]
      },
      "fontStyle": {
        "value": "normal",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "normal",
          "type": "string"
        },
        "name": "FontH6FontStyle",
        "attributes": {
          "category": "font",
          "type": "h6",
          "item": "fontStyle"
        },
        "path": [
          "font",
          "h6",
          "fontStyle"
        ]
      },
      "fontStretch": {
        "value": "normal",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "normal",
          "type": "string"
        },
        "name": "FontH6FontStretch",
        "attributes": {
          "category": "font",
          "type": "h6",
          "item": "fontStretch"
        },
        "path": [
          "font",
          "h6",
          "fontStretch"
        ]
      },
      "fontStyleOld": {
        "value": "Light",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "Light",
          "type": "string"
        },
        "name": "FontH6FontStyleOld",
        "attributes": {
          "category": "font",
          "type": "h6",
          "item": "fontStyleOld"
        },
        "path": [
          "font",
          "h6",
          "fontStyleOld"
        ]
      },
      "letterSpacing": {
        "value": "-2%",
        "type": "number",
        "unit": "percent",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": -2,
          "type": "number",
          "unit": "percent"
        },
        "name": "FontH6LetterSpacing",
        "attributes": {
          "category": "font",
          "type": "h6",
          "item": "letterSpacing",
          "em": "-0.02em"
        },
        "path": [
          "font",
          "h6",
          "letterSpacing"
        ]
      },
      "lineHeight": {
        "value": "120%",
        "type": "number",
        "unit": "percent",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 120,
          "type": "number",
          "unit": "percent"
        },
        "name": "FontH6LineHeight",
        "attributes": {
          "category": "font",
          "type": "h6",
          "item": "lineHeight",
          "em": "1.2em"
        },
        "path": [
          "font",
          "h6",
          "lineHeight"
        ]
      },
      "paragraphIndent": {
        "value": "0px",
        "type": "number",
        "unit": "pixel",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 0,
          "type": "number",
          "unit": "pixel"
        },
        "name": "FontH6ParagraphIndent",
        "attributes": {
          "category": "font",
          "type": "h6",
          "item": "paragraphIndent"
        },
        "path": [
          "font",
          "h6",
          "paragraphIndent"
        ]
      },
      "paragraphSpacing": {
        "value": "0px",
        "type": "number",
        "unit": "pixel",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 0,
          "type": "number",
          "unit": "pixel"
        },
        "name": "FontH6ParagraphSpacing",
        "attributes": {
          "category": "font",
          "type": "h6",
          "item": "paragraphSpacing"
        },
        "path": [
          "font",
          "h6",
          "paragraphSpacing"
        ]
      },
      "textCase": {
        "value": "none",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "none",
          "type": "string"
        },
        "name": "FontH6TextCase",
        "attributes": {
          "category": "font",
          "type": "h6",
          "item": "textCase"
        },
        "path": [
          "font",
          "h6",
          "textCase"
        ]
      }
    },
    "caption": {
      "category": "font",
      "exportKey": "font",
      "fontSize": {
        "value": "14px",
        "type": "number",
        "unit": "pixel",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 14,
          "type": "number",
          "unit": "pixel"
        },
        "name": "FontCaptionFontSize",
        "attributes": {
          "category": "font",
          "type": "caption",
          "item": "fontSize"
        },
        "path": [
          "font",
          "caption",
          "fontSize"
        ]
      },
      "textDecoration": {
        "value": "none",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "none",
          "type": "string"
        },
        "name": "FontCaptionTextDecoration",
        "attributes": {
          "category": "font",
          "type": "caption",
          "item": "textDecoration"
        },
        "path": [
          "font",
          "caption",
          "textDecoration"
        ]
      },
      "fontFamily": {
        "value": "Inter",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "Inter",
          "type": "string"
        },
        "name": "FontCaptionFontFamily",
        "attributes": {
          "category": "font",
          "type": "caption",
          "item": "fontFamily"
        },
        "path": [
          "font",
          "caption",
          "fontFamily"
        ]
      },
      "fontWeight": {
        "value": 400,
        "type": "number",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 400,
          "type": "number"
        },
        "name": "FontCaptionFontWeight",
        "attributes": {
          "category": "font",
          "type": "caption",
          "item": "fontWeight"
        },
        "path": [
          "font",
          "caption",
          "fontWeight"
        ]
      },
      "fontStyle": {
        "value": "normal",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "normal",
          "type": "string"
        },
        "name": "FontCaptionFontStyle",
        "attributes": {
          "category": "font",
          "type": "caption",
          "item": "fontStyle"
        },
        "path": [
          "font",
          "caption",
          "fontStyle"
        ]
      },
      "fontStretch": {
        "value": "normal",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "normal",
          "type": "string"
        },
        "name": "FontCaptionFontStretch",
        "attributes": {
          "category": "font",
          "type": "caption",
          "item": "fontStretch"
        },
        "path": [
          "font",
          "caption",
          "fontStretch"
        ]
      },
      "fontStyleOld": {
        "value": "Regular",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "Regular",
          "type": "string"
        },
        "name": "FontCaptionFontStyleOld",
        "attributes": {
          "category": "font",
          "type": "caption",
          "item": "fontStyleOld"
        },
        "path": [
          "font",
          "caption",
          "fontStyleOld"
        ]
      },
      "letterSpacing": {
        "value": "1%",
        "type": "number",
        "unit": "percent",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 1,
          "type": "number",
          "unit": "percent"
        },
        "name": "FontCaptionLetterSpacing",
        "attributes": {
          "category": "font",
          "type": "caption",
          "item": "letterSpacing",
          "em": "0.01em"
        },
        "path": [
          "font",
          "caption",
          "letterSpacing"
        ]
      },
      "lineHeight": {
        "value": "125%",
        "type": "number",
        "unit": "percent",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 125,
          "type": "number",
          "unit": "percent"
        },
        "name": "FontCaptionLineHeight",
        "attributes": {
          "category": "font",
          "type": "caption",
          "item": "lineHeight",
          "em": "1.25em"
        },
        "path": [
          "font",
          "caption",
          "lineHeight"
        ]
      },
      "paragraphIndent": {
        "value": "0px",
        "type": "number",
        "unit": "pixel",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 0,
          "type": "number",
          "unit": "pixel"
        },
        "name": "FontCaptionParagraphIndent",
        "attributes": {
          "category": "font",
          "type": "caption",
          "item": "paragraphIndent"
        },
        "path": [
          "font",
          "caption",
          "paragraphIndent"
        ]
      },
      "paragraphSpacing": {
        "value": "0px",
        "type": "number",
        "unit": "pixel",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 0,
          "type": "number",
          "unit": "pixel"
        },
        "name": "FontCaptionParagraphSpacing",
        "attributes": {
          "category": "font",
          "type": "caption",
          "item": "paragraphSpacing"
        },
        "path": [
          "font",
          "caption",
          "paragraphSpacing"
        ]
      },
      "textCase": {
        "value": "uppercase",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "uppercase",
          "type": "string"
        },
        "name": "FontCaptionTextCase",
        "attributes": {
          "category": "font",
          "type": "caption",
          "item": "textCase"
        },
        "path": [
          "font",
          "caption",
          "textCase"
        ]
      }
    },
    "shortTextLarge": {
      "category": "font",
      "exportKey": "font",
      "fontSize": {
        "value": "18px",
        "type": "number",
        "unit": "pixel",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 18,
          "type": "number",
          "unit": "pixel"
        },
        "name": "FontShortTextLargeFontSize",
        "attributes": {
          "category": "font",
          "type": "shortTextLarge",
          "item": "fontSize"
        },
        "path": [
          "font",
          "shortTextLarge",
          "fontSize"
        ]
      },
      "textDecoration": {
        "value": "none",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "none",
          "type": "string"
        },
        "name": "FontShortTextLargeTextDecoration",
        "attributes": {
          "category": "font",
          "type": "shortTextLarge",
          "item": "textDecoration"
        },
        "path": [
          "font",
          "shortTextLarge",
          "textDecoration"
        ]
      },
      "fontFamily": {
        "value": "Inter",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "Inter",
          "type": "string"
        },
        "name": "FontShortTextLargeFontFamily",
        "attributes": {
          "category": "font",
          "type": "shortTextLarge",
          "item": "fontFamily"
        },
        "path": [
          "font",
          "shortTextLarge",
          "fontFamily"
        ]
      },
      "fontWeight": {
        "value": 400,
        "type": "number",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 400,
          "type": "number"
        },
        "name": "FontShortTextLargeFontWeight",
        "attributes": {
          "category": "font",
          "type": "shortTextLarge",
          "item": "fontWeight"
        },
        "path": [
          "font",
          "shortTextLarge",
          "fontWeight"
        ]
      },
      "fontStyle": {
        "value": "normal",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "normal",
          "type": "string"
        },
        "name": "FontShortTextLargeFontStyle",
        "attributes": {
          "category": "font",
          "type": "shortTextLarge",
          "item": "fontStyle"
        },
        "path": [
          "font",
          "shortTextLarge",
          "fontStyle"
        ]
      },
      "fontStretch": {
        "value": "normal",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "normal",
          "type": "string"
        },
        "name": "FontShortTextLargeFontStretch",
        "attributes": {
          "category": "font",
          "type": "shortTextLarge",
          "item": "fontStretch"
        },
        "path": [
          "font",
          "shortTextLarge",
          "fontStretch"
        ]
      },
      "fontStyleOld": {
        "value": "Regular",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "Regular",
          "type": "string"
        },
        "name": "FontShortTextLargeFontStyleOld",
        "attributes": {
          "category": "font",
          "type": "shortTextLarge",
          "item": "fontStyleOld"
        },
        "path": [
          "font",
          "shortTextLarge",
          "fontStyleOld"
        ]
      },
      "letterSpacing": {
        "value": "-0.5%",
        "type": "number",
        "unit": "percent",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": -0.5,
          "type": "number",
          "unit": "percent"
        },
        "name": "FontShortTextLargeLetterSpacing",
        "attributes": {
          "category": "font",
          "type": "shortTextLarge",
          "item": "letterSpacing",
          "em": "-0.005em"
        },
        "path": [
          "font",
          "shortTextLarge",
          "letterSpacing"
        ]
      },
      "lineHeight": {
        "value": "140%",
        "type": "number",
        "unit": "percent",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 140,
          "type": "number",
          "unit": "percent"
        },
        "name": "FontShortTextLargeLineHeight",
        "attributes": {
          "category": "font",
          "type": "shortTextLarge",
          "item": "lineHeight",
          "em": "1.4em"
        },
        "path": [
          "font",
          "shortTextLarge",
          "lineHeight"
        ]
      },
      "paragraphIndent": {
        "value": "0px",
        "type": "number",
        "unit": "pixel",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 0,
          "type": "number",
          "unit": "pixel"
        },
        "name": "FontShortTextLargeParagraphIndent",
        "attributes": {
          "category": "font",
          "type": "shortTextLarge",
          "item": "paragraphIndent"
        },
        "path": [
          "font",
          "shortTextLarge",
          "paragraphIndent"
        ]
      },
      "paragraphSpacing": {
        "value": "0px",
        "type": "number",
        "unit": "pixel",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 0,
          "type": "number",
          "unit": "pixel"
        },
        "name": "FontShortTextLargeParagraphSpacing",
        "attributes": {
          "category": "font",
          "type": "shortTextLarge",
          "item": "paragraphSpacing"
        },
        "path": [
          "font",
          "shortTextLarge",
          "paragraphSpacing"
        ]
      },
      "textCase": {
        "value": "none",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "none",
          "type": "string"
        },
        "name": "FontShortTextLargeTextCase",
        "attributes": {
          "category": "font",
          "type": "shortTextLarge",
          "item": "textCase"
        },
        "path": [
          "font",
          "shortTextLarge",
          "textCase"
        ]
      }
    },
    "shortTextDefault": {
      "category": "font",
      "exportKey": "font",
      "fontSize": {
        "value": "16px",
        "type": "number",
        "unit": "pixel",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 16,
          "type": "number",
          "unit": "pixel"
        },
        "name": "FontShortTextDefaultFontSize",
        "attributes": {
          "category": "font",
          "type": "shortTextDefault",
          "item": "fontSize"
        },
        "path": [
          "font",
          "shortTextDefault",
          "fontSize"
        ]
      },
      "textDecoration": {
        "value": "none",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "none",
          "type": "string"
        },
        "name": "FontShortTextDefaultTextDecoration",
        "attributes": {
          "category": "font",
          "type": "shortTextDefault",
          "item": "textDecoration"
        },
        "path": [
          "font",
          "shortTextDefault",
          "textDecoration"
        ]
      },
      "fontFamily": {
        "value": "Inter",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "Inter",
          "type": "string"
        },
        "name": "FontShortTextDefaultFontFamily",
        "attributes": {
          "category": "font",
          "type": "shortTextDefault",
          "item": "fontFamily"
        },
        "path": [
          "font",
          "shortTextDefault",
          "fontFamily"
        ]
      },
      "fontWeight": {
        "value": 400,
        "type": "number",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 400,
          "type": "number"
        },
        "name": "FontShortTextDefaultFontWeight",
        "attributes": {
          "category": "font",
          "type": "shortTextDefault",
          "item": "fontWeight"
        },
        "path": [
          "font",
          "shortTextDefault",
          "fontWeight"
        ]
      },
      "fontStyle": {
        "value": "normal",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "normal",
          "type": "string"
        },
        "name": "FontShortTextDefaultFontStyle",
        "attributes": {
          "category": "font",
          "type": "shortTextDefault",
          "item": "fontStyle"
        },
        "path": [
          "font",
          "shortTextDefault",
          "fontStyle"
        ]
      },
      "fontStretch": {
        "value": "normal",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "normal",
          "type": "string"
        },
        "name": "FontShortTextDefaultFontStretch",
        "attributes": {
          "category": "font",
          "type": "shortTextDefault",
          "item": "fontStretch"
        },
        "path": [
          "font",
          "shortTextDefault",
          "fontStretch"
        ]
      },
      "fontStyleOld": {
        "value": "Regular",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "Regular",
          "type": "string"
        },
        "name": "FontShortTextDefaultFontStyleOld",
        "attributes": {
          "category": "font",
          "type": "shortTextDefault",
          "item": "fontStyleOld"
        },
        "path": [
          "font",
          "shortTextDefault",
          "fontStyleOld"
        ]
      },
      "letterSpacing": {
        "value": "-0.5%",
        "type": "number",
        "unit": "percent",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": -0.5,
          "type": "number",
          "unit": "percent"
        },
        "name": "FontShortTextDefaultLetterSpacing",
        "attributes": {
          "category": "font",
          "type": "shortTextDefault",
          "item": "letterSpacing",
          "em": "-0.005em"
        },
        "path": [
          "font",
          "shortTextDefault",
          "letterSpacing"
        ]
      },
      "lineHeight": {
        "value": "130%",
        "type": "number",
        "unit": "percent",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 130,
          "type": "number",
          "unit": "percent"
        },
        "name": "FontShortTextDefaultLineHeight",
        "attributes": {
          "category": "font",
          "type": "shortTextDefault",
          "item": "lineHeight",
          "em": "1.3em"
        },
        "path": [
          "font",
          "shortTextDefault",
          "lineHeight"
        ]
      },
      "paragraphIndent": {
        "value": "0px",
        "type": "number",
        "unit": "pixel",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 0,
          "type": "number",
          "unit": "pixel"
        },
        "name": "FontShortTextDefaultParagraphIndent",
        "attributes": {
          "category": "font",
          "type": "shortTextDefault",
          "item": "paragraphIndent"
        },
        "path": [
          "font",
          "shortTextDefault",
          "paragraphIndent"
        ]
      },
      "paragraphSpacing": {
        "value": "0px",
        "type": "number",
        "unit": "pixel",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 0,
          "type": "number",
          "unit": "pixel"
        },
        "name": "FontShortTextDefaultParagraphSpacing",
        "attributes": {
          "category": "font",
          "type": "shortTextDefault",
          "item": "paragraphSpacing"
        },
        "path": [
          "font",
          "shortTextDefault",
          "paragraphSpacing"
        ]
      },
      "textCase": {
        "value": "none",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "none",
          "type": "string"
        },
        "name": "FontShortTextDefaultTextCase",
        "attributes": {
          "category": "font",
          "type": "shortTextDefault",
          "item": "textCase"
        },
        "path": [
          "font",
          "shortTextDefault",
          "textCase"
        ]
      }
    },
    "shortTextSmall": {
      "category": "font",
      "exportKey": "font",
      "comment": "Use this for smaller text.\n",
      "fontSize": {
        "value": "14px",
        "type": "number",
        "unit": "pixel",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 14,
          "type": "number",
          "unit": "pixel"
        },
        "name": "FontShortTextSmallFontSize",
        "attributes": {
          "category": "font",
          "type": "shortTextSmall",
          "item": "fontSize"
        },
        "path": [
          "font",
          "shortTextSmall",
          "fontSize"
        ]
      },
      "textDecoration": {
        "value": "none",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "none",
          "type": "string"
        },
        "name": "FontShortTextSmallTextDecoration",
        "attributes": {
          "category": "font",
          "type": "shortTextSmall",
          "item": "textDecoration"
        },
        "path": [
          "font",
          "shortTextSmall",
          "textDecoration"
        ]
      },
      "fontFamily": {
        "value": "Inter",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "Inter",
          "type": "string"
        },
        "name": "FontShortTextSmallFontFamily",
        "attributes": {
          "category": "font",
          "type": "shortTextSmall",
          "item": "fontFamily"
        },
        "path": [
          "font",
          "shortTextSmall",
          "fontFamily"
        ]
      },
      "fontWeight": {
        "value": 400,
        "type": "number",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 400,
          "type": "number"
        },
        "name": "FontShortTextSmallFontWeight",
        "attributes": {
          "category": "font",
          "type": "shortTextSmall",
          "item": "fontWeight"
        },
        "path": [
          "font",
          "shortTextSmall",
          "fontWeight"
        ]
      },
      "fontStyle": {
        "value": "normal",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "normal",
          "type": "string"
        },
        "name": "FontShortTextSmallFontStyle",
        "attributes": {
          "category": "font",
          "type": "shortTextSmall",
          "item": "fontStyle"
        },
        "path": [
          "font",
          "shortTextSmall",
          "fontStyle"
        ]
      },
      "fontStretch": {
        "value": "normal",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "normal",
          "type": "string"
        },
        "name": "FontShortTextSmallFontStretch",
        "attributes": {
          "category": "font",
          "type": "shortTextSmall",
          "item": "fontStretch"
        },
        "path": [
          "font",
          "shortTextSmall",
          "fontStretch"
        ]
      },
      "fontStyleOld": {
        "value": "Regular",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "Regular",
          "type": "string"
        },
        "name": "FontShortTextSmallFontStyleOld",
        "attributes": {
          "category": "font",
          "type": "shortTextSmall",
          "item": "fontStyleOld"
        },
        "path": [
          "font",
          "shortTextSmall",
          "fontStyleOld"
        ]
      },
      "letterSpacing": {
        "value": "-0.25%",
        "type": "number",
        "unit": "percent",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": -0.25,
          "type": "number",
          "unit": "percent"
        },
        "name": "FontShortTextSmallLetterSpacing",
        "attributes": {
          "category": "font",
          "type": "shortTextSmall",
          "item": "letterSpacing",
          "em": "-0.0025em"
        },
        "path": [
          "font",
          "shortTextSmall",
          "letterSpacing"
        ]
      },
      "lineHeight": {
        "value": "143%",
        "type": "number",
        "unit": "percent",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 143,
          "type": "number",
          "unit": "percent"
        },
        "name": "FontShortTextSmallLineHeight",
        "attributes": {
          "category": "font",
          "type": "shortTextSmall",
          "item": "lineHeight",
          "em": "1.43em"
        },
        "path": [
          "font",
          "shortTextSmall",
          "lineHeight"
        ]
      },
      "paragraphIndent": {
        "value": "0px",
        "type": "number",
        "unit": "pixel",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 0,
          "type": "number",
          "unit": "pixel"
        },
        "name": "FontShortTextSmallParagraphIndent",
        "attributes": {
          "category": "font",
          "type": "shortTextSmall",
          "item": "paragraphIndent"
        },
        "path": [
          "font",
          "shortTextSmall",
          "paragraphIndent"
        ]
      },
      "paragraphSpacing": {
        "value": "0px",
        "type": "number",
        "unit": "pixel",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 0,
          "type": "number",
          "unit": "pixel"
        },
        "name": "FontShortTextSmallParagraphSpacing",
        "attributes": {
          "category": "font",
          "type": "shortTextSmall",
          "item": "paragraphSpacing"
        },
        "path": [
          "font",
          "shortTextSmall",
          "paragraphSpacing"
        ]
      },
      "textCase": {
        "value": "none",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "none",
          "type": "string"
        },
        "name": "FontShortTextSmallTextCase",
        "attributes": {
          "category": "font",
          "type": "shortTextSmall",
          "item": "textCase"
        },
        "path": [
          "font",
          "shortTextSmall",
          "textCase"
        ]
      }
    },
    "shortTextXsmall": {
      "category": "font",
      "exportKey": "font",
      "fontSize": {
        "value": "12px",
        "type": "number",
        "unit": "pixel",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 12,
          "type": "number",
          "unit": "pixel"
        },
        "name": "FontShortTextXsmallFontSize",
        "attributes": {
          "category": "font",
          "type": "shortTextXsmall",
          "item": "fontSize"
        },
        "path": [
          "font",
          "shortTextXsmall",
          "fontSize"
        ]
      },
      "textDecoration": {
        "value": "none",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "none",
          "type": "string"
        },
        "name": "FontShortTextXsmallTextDecoration",
        "attributes": {
          "category": "font",
          "type": "shortTextXsmall",
          "item": "textDecoration"
        },
        "path": [
          "font",
          "shortTextXsmall",
          "textDecoration"
        ]
      },
      "fontFamily": {
        "value": "Inter",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "Inter",
          "type": "string"
        },
        "name": "FontShortTextXsmallFontFamily",
        "attributes": {
          "category": "font",
          "type": "shortTextXsmall",
          "item": "fontFamily"
        },
        "path": [
          "font",
          "shortTextXsmall",
          "fontFamily"
        ]
      },
      "fontWeight": {
        "value": 400,
        "type": "number",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 400,
          "type": "number"
        },
        "name": "FontShortTextXsmallFontWeight",
        "attributes": {
          "category": "font",
          "type": "shortTextXsmall",
          "item": "fontWeight"
        },
        "path": [
          "font",
          "shortTextXsmall",
          "fontWeight"
        ]
      },
      "fontStyle": {
        "value": "normal",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "normal",
          "type": "string"
        },
        "name": "FontShortTextXsmallFontStyle",
        "attributes": {
          "category": "font",
          "type": "shortTextXsmall",
          "item": "fontStyle"
        },
        "path": [
          "font",
          "shortTextXsmall",
          "fontStyle"
        ]
      },
      "fontStretch": {
        "value": "normal",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "normal",
          "type": "string"
        },
        "name": "FontShortTextXsmallFontStretch",
        "attributes": {
          "category": "font",
          "type": "shortTextXsmall",
          "item": "fontStretch"
        },
        "path": [
          "font",
          "shortTextXsmall",
          "fontStretch"
        ]
      },
      "fontStyleOld": {
        "value": "Regular",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "Regular",
          "type": "string"
        },
        "name": "FontShortTextXsmallFontStyleOld",
        "attributes": {
          "category": "font",
          "type": "shortTextXsmall",
          "item": "fontStyleOld"
        },
        "path": [
          "font",
          "shortTextXsmall",
          "fontStyleOld"
        ]
      },
      "letterSpacing": {
        "value": "0%",
        "type": "number",
        "unit": "percent",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 0,
          "type": "number",
          "unit": "percent"
        },
        "name": "FontShortTextXsmallLetterSpacing",
        "attributes": {
          "category": "font",
          "type": "shortTextXsmall",
          "item": "letterSpacing",
          "em": "0em"
        },
        "path": [
          "font",
          "shortTextXsmall",
          "letterSpacing"
        ]
      },
      "lineHeight": {
        "value": "150%",
        "type": "number",
        "unit": "percent",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 150,
          "type": "number",
          "unit": "percent"
        },
        "name": "FontShortTextXsmallLineHeight",
        "attributes": {
          "category": "font",
          "type": "shortTextXsmall",
          "item": "lineHeight",
          "em": "1.5em"
        },
        "path": [
          "font",
          "shortTextXsmall",
          "lineHeight"
        ]
      },
      "paragraphIndent": {
        "value": "0px",
        "type": "number",
        "unit": "pixel",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 0,
          "type": "number",
          "unit": "pixel"
        },
        "name": "FontShortTextXsmallParagraphIndent",
        "attributes": {
          "category": "font",
          "type": "shortTextXsmall",
          "item": "paragraphIndent"
        },
        "path": [
          "font",
          "shortTextXsmall",
          "paragraphIndent"
        ]
      },
      "paragraphSpacing": {
        "value": "0px",
        "type": "number",
        "unit": "pixel",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 0,
          "type": "number",
          "unit": "pixel"
        },
        "name": "FontShortTextXsmallParagraphSpacing",
        "attributes": {
          "category": "font",
          "type": "shortTextXsmall",
          "item": "paragraphSpacing"
        },
        "path": [
          "font",
          "shortTextXsmall",
          "paragraphSpacing"
        ]
      },
      "textCase": {
        "value": "none",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "none",
          "type": "string"
        },
        "name": "FontShortTextXsmallTextCase",
        "attributes": {
          "category": "font",
          "type": "shortTextXsmall",
          "item": "textCase"
        },
        "path": [
          "font",
          "shortTextXsmall",
          "textCase"
        ]
      }
    },
    "longTextDefault": {
      "category": "font",
      "exportKey": "font",
      "comment": "Use this for long texts. ",
      "fontSize": {
        "value": "17px",
        "type": "number",
        "unit": "pixel",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 17,
          "type": "number",
          "unit": "pixel"
        },
        "name": "FontLongTextDefaultFontSize",
        "attributes": {
          "category": "font",
          "type": "longTextDefault",
          "item": "fontSize"
        },
        "path": [
          "font",
          "longTextDefault",
          "fontSize"
        ]
      },
      "textDecoration": {
        "value": "none",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "none",
          "type": "string"
        },
        "name": "FontLongTextDefaultTextDecoration",
        "attributes": {
          "category": "font",
          "type": "longTextDefault",
          "item": "textDecoration"
        },
        "path": [
          "font",
          "longTextDefault",
          "textDecoration"
        ]
      },
      "fontFamily": {
        "value": "Inter",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "Inter",
          "type": "string"
        },
        "name": "FontLongTextDefaultFontFamily",
        "attributes": {
          "category": "font",
          "type": "longTextDefault",
          "item": "fontFamily"
        },
        "path": [
          "font",
          "longTextDefault",
          "fontFamily"
        ]
      },
      "fontWeight": {
        "value": 400,
        "type": "number",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 400,
          "type": "number"
        },
        "name": "FontLongTextDefaultFontWeight",
        "attributes": {
          "category": "font",
          "type": "longTextDefault",
          "item": "fontWeight"
        },
        "path": [
          "font",
          "longTextDefault",
          "fontWeight"
        ]
      },
      "fontStyle": {
        "value": "normal",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "normal",
          "type": "string"
        },
        "name": "FontLongTextDefaultFontStyle",
        "attributes": {
          "category": "font",
          "type": "longTextDefault",
          "item": "fontStyle"
        },
        "path": [
          "font",
          "longTextDefault",
          "fontStyle"
        ]
      },
      "fontStretch": {
        "value": "normal",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "normal",
          "type": "string"
        },
        "name": "FontLongTextDefaultFontStretch",
        "attributes": {
          "category": "font",
          "type": "longTextDefault",
          "item": "fontStretch"
        },
        "path": [
          "font",
          "longTextDefault",
          "fontStretch"
        ]
      },
      "fontStyleOld": {
        "value": "Regular",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "Regular",
          "type": "string"
        },
        "name": "FontLongTextDefaultFontStyleOld",
        "attributes": {
          "category": "font",
          "type": "longTextDefault",
          "item": "fontStyleOld"
        },
        "path": [
          "font",
          "longTextDefault",
          "fontStyleOld"
        ]
      },
      "letterSpacing": {
        "value": "0%",
        "type": "number",
        "unit": "percent",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 0,
          "type": "number",
          "unit": "percent"
        },
        "name": "FontLongTextDefaultLetterSpacing",
        "attributes": {
          "category": "font",
          "type": "longTextDefault",
          "item": "letterSpacing",
          "em": "0em"
        },
        "path": [
          "font",
          "longTextDefault",
          "letterSpacing"
        ]
      },
      "lineHeight": {
        "value": "170%",
        "type": "number",
        "unit": "percent",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 170,
          "type": "number",
          "unit": "percent"
        },
        "name": "FontLongTextDefaultLineHeight",
        "attributes": {
          "category": "font",
          "type": "longTextDefault",
          "item": "lineHeight",
          "em": "1.7em"
        },
        "path": [
          "font",
          "longTextDefault",
          "lineHeight"
        ]
      },
      "paragraphIndent": {
        "value": "0px",
        "type": "number",
        "unit": "pixel",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 0,
          "type": "number",
          "unit": "pixel"
        },
        "name": "FontLongTextDefaultParagraphIndent",
        "attributes": {
          "category": "font",
          "type": "longTextDefault",
          "item": "paragraphIndent"
        },
        "path": [
          "font",
          "longTextDefault",
          "paragraphIndent"
        ]
      },
      "paragraphSpacing": {
        "value": "0px",
        "type": "number",
        "unit": "pixel",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 0,
          "type": "number",
          "unit": "pixel"
        },
        "name": "FontLongTextDefaultParagraphSpacing",
        "attributes": {
          "category": "font",
          "type": "longTextDefault",
          "item": "paragraphSpacing"
        },
        "path": [
          "font",
          "longTextDefault",
          "paragraphSpacing"
        ]
      },
      "textCase": {
        "value": "none",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "none",
          "type": "string"
        },
        "name": "FontLongTextDefaultTextCase",
        "attributes": {
          "category": "font",
          "type": "longTextDefault",
          "item": "textCase"
        },
        "path": [
          "font",
          "longTextDefault",
          "textCase"
        ]
      }
    },
    "longTextSmall": {
      "category": "font",
      "exportKey": "font",
      "comment": "Use this as default text token.",
      "fontSize": {
        "value": "16px",
        "type": "number",
        "unit": "pixel",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 16,
          "type": "number",
          "unit": "pixel"
        },
        "name": "FontLongTextSmallFontSize",
        "attributes": {
          "category": "font",
          "type": "longTextSmall",
          "item": "fontSize"
        },
        "path": [
          "font",
          "longTextSmall",
          "fontSize"
        ]
      },
      "textDecoration": {
        "value": "none",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "none",
          "type": "string"
        },
        "name": "FontLongTextSmallTextDecoration",
        "attributes": {
          "category": "font",
          "type": "longTextSmall",
          "item": "textDecoration"
        },
        "path": [
          "font",
          "longTextSmall",
          "textDecoration"
        ]
      },
      "fontFamily": {
        "value": "Inter",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "Inter",
          "type": "string"
        },
        "name": "FontLongTextSmallFontFamily",
        "attributes": {
          "category": "font",
          "type": "longTextSmall",
          "item": "fontFamily"
        },
        "path": [
          "font",
          "longTextSmall",
          "fontFamily"
        ]
      },
      "fontWeight": {
        "value": 400,
        "type": "number",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 400,
          "type": "number"
        },
        "name": "FontLongTextSmallFontWeight",
        "attributes": {
          "category": "font",
          "type": "longTextSmall",
          "item": "fontWeight"
        },
        "path": [
          "font",
          "longTextSmall",
          "fontWeight"
        ]
      },
      "fontStyle": {
        "value": "normal",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "normal",
          "type": "string"
        },
        "name": "FontLongTextSmallFontStyle",
        "attributes": {
          "category": "font",
          "type": "longTextSmall",
          "item": "fontStyle"
        },
        "path": [
          "font",
          "longTextSmall",
          "fontStyle"
        ]
      },
      "fontStretch": {
        "value": "normal",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "normal",
          "type": "string"
        },
        "name": "FontLongTextSmallFontStretch",
        "attributes": {
          "category": "font",
          "type": "longTextSmall",
          "item": "fontStretch"
        },
        "path": [
          "font",
          "longTextSmall",
          "fontStretch"
        ]
      },
      "fontStyleOld": {
        "value": "Regular",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "Regular",
          "type": "string"
        },
        "name": "FontLongTextSmallFontStyleOld",
        "attributes": {
          "category": "font",
          "type": "longTextSmall",
          "item": "fontStyleOld"
        },
        "path": [
          "font",
          "longTextSmall",
          "fontStyleOld"
        ]
      },
      "letterSpacing": {
        "value": "0%",
        "type": "number",
        "unit": "percent",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 0,
          "type": "number",
          "unit": "percent"
        },
        "name": "FontLongTextSmallLetterSpacing",
        "attributes": {
          "category": "font",
          "type": "longTextSmall",
          "item": "letterSpacing",
          "em": "0em"
        },
        "path": [
          "font",
          "longTextSmall",
          "letterSpacing"
        ]
      },
      "lineHeight": {
        "value": "150%",
        "type": "number",
        "unit": "percent",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 150,
          "type": "number",
          "unit": "percent"
        },
        "name": "FontLongTextSmallLineHeight",
        "attributes": {
          "category": "font",
          "type": "longTextSmall",
          "item": "lineHeight",
          "em": "1.5em"
        },
        "path": [
          "font",
          "longTextSmall",
          "lineHeight"
        ]
      },
      "paragraphIndent": {
        "value": "0px",
        "type": "number",
        "unit": "pixel",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 0,
          "type": "number",
          "unit": "pixel"
        },
        "name": "FontLongTextSmallParagraphIndent",
        "attributes": {
          "category": "font",
          "type": "longTextSmall",
          "item": "paragraphIndent"
        },
        "path": [
          "font",
          "longTextSmall",
          "paragraphIndent"
        ]
      },
      "paragraphSpacing": {
        "value": "0px",
        "type": "number",
        "unit": "pixel",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 0,
          "type": "number",
          "unit": "pixel"
        },
        "name": "FontLongTextSmallParagraphSpacing",
        "attributes": {
          "category": "font",
          "type": "longTextSmall",
          "item": "paragraphSpacing"
        },
        "path": [
          "font",
          "longTextSmall",
          "paragraphSpacing"
        ]
      },
      "textCase": {
        "value": "none",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "none",
          "type": "string"
        },
        "name": "FontLongTextSmallTextCase",
        "attributes": {
          "category": "font",
          "type": "longTextSmall",
          "item": "textCase"
        },
        "path": [
          "font",
          "longTextSmall",
          "textCase"
        ]
      }
    },
    "button": {
      "category": "font",
      "exportKey": "font",
      "fontSize": {
        "value": "14px",
        "type": "number",
        "unit": "pixel",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 14,
          "type": "number",
          "unit": "pixel"
        },
        "name": "FontButtonFontSize",
        "attributes": {
          "category": "font",
          "type": "button",
          "item": "fontSize"
        },
        "path": [
          "font",
          "button",
          "fontSize"
        ]
      },
      "textDecoration": {
        "value": "none",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "none",
          "type": "string"
        },
        "name": "FontButtonTextDecoration",
        "attributes": {
          "category": "font",
          "type": "button",
          "item": "textDecoration"
        },
        "path": [
          "font",
          "button",
          "textDecoration"
        ]
      },
      "fontFamily": {
        "value": "Inter",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "Inter",
          "type": "string"
        },
        "name": "FontButtonFontFamily",
        "attributes": {
          "category": "font",
          "type": "button",
          "item": "fontFamily"
        },
        "path": [
          "font",
          "button",
          "fontFamily"
        ]
      },
      "fontWeight": {
        "value": 500,
        "type": "number",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 500,
          "type": "number"
        },
        "name": "FontButtonFontWeight",
        "attributes": {
          "category": "font",
          "type": "button",
          "item": "fontWeight"
        },
        "path": [
          "font",
          "button",
          "fontWeight"
        ]
      },
      "fontStyle": {
        "value": "normal",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "normal",
          "type": "string"
        },
        "name": "FontButtonFontStyle",
        "attributes": {
          "category": "font",
          "type": "button",
          "item": "fontStyle"
        },
        "path": [
          "font",
          "button",
          "fontStyle"
        ]
      },
      "fontStretch": {
        "value": "normal",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "normal",
          "type": "string"
        },
        "name": "FontButtonFontStretch",
        "attributes": {
          "category": "font",
          "type": "button",
          "item": "fontStretch"
        },
        "path": [
          "font",
          "button",
          "fontStretch"
        ]
      },
      "fontStyleOld": {
        "value": "Medium",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "Medium",
          "type": "string"
        },
        "name": "FontButtonFontStyleOld",
        "attributes": {
          "category": "font",
          "type": "button",
          "item": "fontStyleOld"
        },
        "path": [
          "font",
          "button",
          "fontStyleOld"
        ]
      },
      "letterSpacing": {
        "value": "1%",
        "type": "number",
        "unit": "percent",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 1,
          "type": "number",
          "unit": "percent"
        },
        "name": "FontButtonLetterSpacing",
        "attributes": {
          "category": "font",
          "type": "button",
          "item": "letterSpacing",
          "em": "0.01em"
        },
        "path": [
          "font",
          "button",
          "letterSpacing"
        ]
      },
      "lineHeight": {
        "value": "125%",
        "type": "number",
        "unit": "percent",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 125,
          "type": "number",
          "unit": "percent"
        },
        "name": "FontButtonLineHeight",
        "attributes": {
          "category": "font",
          "type": "button",
          "item": "lineHeight",
          "em": "1.25em"
        },
        "path": [
          "font",
          "button",
          "lineHeight"
        ]
      },
      "paragraphIndent": {
        "value": "0px",
        "type": "number",
        "unit": "pixel",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 0,
          "type": "number",
          "unit": "pixel"
        },
        "name": "FontButtonParagraphIndent",
        "attributes": {
          "category": "font",
          "type": "button",
          "item": "paragraphIndent"
        },
        "path": [
          "font",
          "button",
          "paragraphIndent"
        ]
      },
      "paragraphSpacing": {
        "value": "0px",
        "type": "number",
        "unit": "pixel",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 0,
          "type": "number",
          "unit": "pixel"
        },
        "name": "FontButtonParagraphSpacing",
        "attributes": {
          "category": "font",
          "type": "button",
          "item": "paragraphSpacing"
        },
        "path": [
          "font",
          "button",
          "paragraphSpacing"
        ]
      },
      "textCase": {
        "value": "uppercase",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "uppercase",
          "type": "string"
        },
        "name": "FontButtonTextCase",
        "attributes": {
          "category": "font",
          "type": "button",
          "item": "textCase"
        },
        "path": [
          "font",
          "button",
          "textCase"
        ]
      }
    },
    "tab": {
      "category": "font",
      "exportKey": "font",
      "fontSize": {
        "value": "16px",
        "type": "number",
        "unit": "pixel",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 16,
          "type": "number",
          "unit": "pixel"
        },
        "name": "FontTabFontSize",
        "attributes": {
          "category": "font",
          "type": "tab",
          "item": "fontSize"
        },
        "path": [
          "font",
          "tab",
          "fontSize"
        ]
      },
      "textDecoration": {
        "value": "none",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "none",
          "type": "string"
        },
        "name": "FontTabTextDecoration",
        "attributes": {
          "category": "font",
          "type": "tab",
          "item": "textDecoration"
        },
        "path": [
          "font",
          "tab",
          "textDecoration"
        ]
      },
      "fontFamily": {
        "value": "Inter",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "Inter",
          "type": "string"
        },
        "name": "FontTabFontFamily",
        "attributes": {
          "category": "font",
          "type": "tab",
          "item": "fontFamily"
        },
        "path": [
          "font",
          "tab",
          "fontFamily"
        ]
      },
      "fontWeight": {
        "value": 400,
        "type": "number",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 400,
          "type": "number"
        },
        "name": "FontTabFontWeight",
        "attributes": {
          "category": "font",
          "type": "tab",
          "item": "fontWeight"
        },
        "path": [
          "font",
          "tab",
          "fontWeight"
        ]
      },
      "fontStyle": {
        "value": "normal",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "normal",
          "type": "string"
        },
        "name": "FontTabFontStyle",
        "attributes": {
          "category": "font",
          "type": "tab",
          "item": "fontStyle"
        },
        "path": [
          "font",
          "tab",
          "fontStyle"
        ]
      },
      "fontStretch": {
        "value": "normal",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "normal",
          "type": "string"
        },
        "name": "FontTabFontStretch",
        "attributes": {
          "category": "font",
          "type": "tab",
          "item": "fontStretch"
        },
        "path": [
          "font",
          "tab",
          "fontStretch"
        ]
      },
      "fontStyleOld": {
        "value": "Regular",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "Regular",
          "type": "string"
        },
        "name": "FontTabFontStyleOld",
        "attributes": {
          "category": "font",
          "type": "tab",
          "item": "fontStyleOld"
        },
        "path": [
          "font",
          "tab",
          "fontStyleOld"
        ]
      },
      "letterSpacing": {
        "value": "-1%",
        "type": "number",
        "unit": "percent",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": -1,
          "type": "number",
          "unit": "percent"
        },
        "name": "FontTabLetterSpacing",
        "attributes": {
          "category": "font",
          "type": "tab",
          "item": "letterSpacing",
          "em": "-0.01em"
        },
        "path": [
          "font",
          "tab",
          "letterSpacing"
        ]
      },
      "lineHeight": {
        "value": "125%",
        "type": "number",
        "unit": "percent",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 125,
          "type": "number",
          "unit": "percent"
        },
        "name": "FontTabLineHeight",
        "attributes": {
          "category": "font",
          "type": "tab",
          "item": "lineHeight",
          "em": "1.25em"
        },
        "path": [
          "font",
          "tab",
          "lineHeight"
        ]
      },
      "paragraphIndent": {
        "value": "0px",
        "type": "number",
        "unit": "pixel",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 0,
          "type": "number",
          "unit": "pixel"
        },
        "name": "FontTabParagraphIndent",
        "attributes": {
          "category": "font",
          "type": "tab",
          "item": "paragraphIndent"
        },
        "path": [
          "font",
          "tab",
          "paragraphIndent"
        ]
      },
      "paragraphSpacing": {
        "value": "0px",
        "type": "number",
        "unit": "pixel",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 0,
          "type": "number",
          "unit": "pixel"
        },
        "name": "FontTabParagraphSpacing",
        "attributes": {
          "category": "font",
          "type": "tab",
          "item": "paragraphSpacing"
        },
        "path": [
          "font",
          "tab",
          "paragraphSpacing"
        ]
      },
      "textCase": {
        "value": "none",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "none",
          "type": "string"
        },
        "name": "FontTabTextCase",
        "attributes": {
          "category": "font",
          "type": "tab",
          "item": "textCase"
        },
        "path": [
          "font",
          "tab",
          "textCase"
        ]
      }
    },
    "beadcrumb": {
      "category": "font",
      "exportKey": "font",
      "fontSize": {
        "value": "16px",
        "type": "number",
        "unit": "pixel",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 16,
          "type": "number",
          "unit": "pixel"
        },
        "name": "FontBeadcrumbFontSize",
        "attributes": {
          "category": "font",
          "type": "beadcrumb",
          "item": "fontSize"
        },
        "path": [
          "font",
          "beadcrumb",
          "fontSize"
        ]
      },
      "textDecoration": {
        "value": "none",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "none",
          "type": "string"
        },
        "name": "FontBeadcrumbTextDecoration",
        "attributes": {
          "category": "font",
          "type": "beadcrumb",
          "item": "textDecoration"
        },
        "path": [
          "font",
          "beadcrumb",
          "textDecoration"
        ]
      },
      "fontFamily": {
        "value": "Inter",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "Inter",
          "type": "string"
        },
        "name": "FontBeadcrumbFontFamily",
        "attributes": {
          "category": "font",
          "type": "beadcrumb",
          "item": "fontFamily"
        },
        "path": [
          "font",
          "beadcrumb",
          "fontFamily"
        ]
      },
      "fontWeight": {
        "value": 400,
        "type": "number",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 400,
          "type": "number"
        },
        "name": "FontBeadcrumbFontWeight",
        "attributes": {
          "category": "font",
          "type": "beadcrumb",
          "item": "fontWeight"
        },
        "path": [
          "font",
          "beadcrumb",
          "fontWeight"
        ]
      },
      "fontStyle": {
        "value": "normal",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "normal",
          "type": "string"
        },
        "name": "FontBeadcrumbFontStyle",
        "attributes": {
          "category": "font",
          "type": "beadcrumb",
          "item": "fontStyle"
        },
        "path": [
          "font",
          "beadcrumb",
          "fontStyle"
        ]
      },
      "fontStretch": {
        "value": "normal",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "normal",
          "type": "string"
        },
        "name": "FontBeadcrumbFontStretch",
        "attributes": {
          "category": "font",
          "type": "beadcrumb",
          "item": "fontStretch"
        },
        "path": [
          "font",
          "beadcrumb",
          "fontStretch"
        ]
      },
      "fontStyleOld": {
        "value": "Regular",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "Regular",
          "type": "string"
        },
        "name": "FontBeadcrumbFontStyleOld",
        "attributes": {
          "category": "font",
          "type": "beadcrumb",
          "item": "fontStyleOld"
        },
        "path": [
          "font",
          "beadcrumb",
          "fontStyleOld"
        ]
      },
      "letterSpacing": {
        "value": "0%",
        "type": "number",
        "unit": "percent",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 0,
          "type": "number",
          "unit": "percent"
        },
        "name": "FontBeadcrumbLetterSpacing",
        "attributes": {
          "category": "font",
          "type": "beadcrumb",
          "item": "letterSpacing",
          "em": "0em"
        },
        "path": [
          "font",
          "beadcrumb",
          "letterSpacing"
        ]
      },
      "lineHeight": {
        "value": "150%",
        "type": "number",
        "unit": "percent",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 150,
          "type": "number",
          "unit": "percent"
        },
        "name": "FontBeadcrumbLineHeight",
        "attributes": {
          "category": "font",
          "type": "beadcrumb",
          "item": "lineHeight",
          "em": "1.5em"
        },
        "path": [
          "font",
          "beadcrumb",
          "lineHeight"
        ]
      },
      "paragraphIndent": {
        "value": "0px",
        "type": "number",
        "unit": "pixel",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 0,
          "type": "number",
          "unit": "pixel"
        },
        "name": "FontBeadcrumbParagraphIndent",
        "attributes": {
          "category": "font",
          "type": "beadcrumb",
          "item": "paragraphIndent"
        },
        "path": [
          "font",
          "beadcrumb",
          "paragraphIndent"
        ]
      },
      "paragraphSpacing": {
        "value": "0px",
        "type": "number",
        "unit": "pixel",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 0,
          "type": "number",
          "unit": "pixel"
        },
        "name": "FontBeadcrumbParagraphSpacing",
        "attributes": {
          "category": "font",
          "type": "beadcrumb",
          "item": "paragraphSpacing"
        },
        "path": [
          "font",
          "beadcrumb",
          "paragraphSpacing"
        ]
      },
      "textCase": {
        "value": "none",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "none",
          "type": "string"
        },
        "name": "FontBeadcrumbTextCase",
        "attributes": {
          "category": "font",
          "type": "beadcrumb",
          "item": "textCase"
        },
        "path": [
          "font",
          "beadcrumb",
          "textCase"
        ]
      }
    },
    "shortTextXxsmall": {
      "category": "font",
      "exportKey": "font",
      "fontSize": {
        "value": "10px",
        "type": "number",
        "unit": "pixel",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 10,
          "type": "number",
          "unit": "pixel"
        },
        "name": "FontShortTextXxsmallFontSize",
        "attributes": {
          "category": "font",
          "type": "shortTextXxsmall",
          "item": "fontSize"
        },
        "path": [
          "font",
          "shortTextXxsmall",
          "fontSize"
        ]
      },
      "textDecoration": {
        "value": "none",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "none",
          "type": "string"
        },
        "name": "FontShortTextXxsmallTextDecoration",
        "attributes": {
          "category": "font",
          "type": "shortTextXxsmall",
          "item": "textDecoration"
        },
        "path": [
          "font",
          "shortTextXxsmall",
          "textDecoration"
        ]
      },
      "fontFamily": {
        "value": "Inter",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "Inter",
          "type": "string"
        },
        "name": "FontShortTextXxsmallFontFamily",
        "attributes": {
          "category": "font",
          "type": "shortTextXxsmall",
          "item": "fontFamily"
        },
        "path": [
          "font",
          "shortTextXxsmall",
          "fontFamily"
        ]
      },
      "fontWeight": {
        "value": 400,
        "type": "number",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 400,
          "type": "number"
        },
        "name": "FontShortTextXxsmallFontWeight",
        "attributes": {
          "category": "font",
          "type": "shortTextXxsmall",
          "item": "fontWeight"
        },
        "path": [
          "font",
          "shortTextXxsmall",
          "fontWeight"
        ]
      },
      "fontStyle": {
        "value": "normal",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "normal",
          "type": "string"
        },
        "name": "FontShortTextXxsmallFontStyle",
        "attributes": {
          "category": "font",
          "type": "shortTextXxsmall",
          "item": "fontStyle"
        },
        "path": [
          "font",
          "shortTextXxsmall",
          "fontStyle"
        ]
      },
      "fontStretch": {
        "value": "normal",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "normal",
          "type": "string"
        },
        "name": "FontShortTextXxsmallFontStretch",
        "attributes": {
          "category": "font",
          "type": "shortTextXxsmall",
          "item": "fontStretch"
        },
        "path": [
          "font",
          "shortTextXxsmall",
          "fontStretch"
        ]
      },
      "fontStyleOld": {
        "value": "Regular",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "Regular",
          "type": "string"
        },
        "name": "FontShortTextXxsmallFontStyleOld",
        "attributes": {
          "category": "font",
          "type": "shortTextXxsmall",
          "item": "fontStyleOld"
        },
        "path": [
          "font",
          "shortTextXxsmall",
          "fontStyleOld"
        ]
      },
      "letterSpacing": {
        "value": "0%",
        "type": "number",
        "unit": "percent",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 0,
          "type": "number",
          "unit": "percent"
        },
        "name": "FontShortTextXxsmallLetterSpacing",
        "attributes": {
          "category": "font",
          "type": "shortTextXxsmall",
          "item": "letterSpacing",
          "em": "0em"
        },
        "path": [
          "font",
          "shortTextXxsmall",
          "letterSpacing"
        ]
      },
      "lineHeight": {
        "value": "150%",
        "type": "number",
        "unit": "percent",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 150,
          "type": "number",
          "unit": "percent"
        },
        "name": "FontShortTextXxsmallLineHeight",
        "attributes": {
          "category": "font",
          "type": "shortTextXxsmall",
          "item": "lineHeight",
          "em": "1.5em"
        },
        "path": [
          "font",
          "shortTextXxsmall",
          "lineHeight"
        ]
      },
      "paragraphIndent": {
        "value": "0px",
        "type": "number",
        "unit": "pixel",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 0,
          "type": "number",
          "unit": "pixel"
        },
        "name": "FontShortTextXxsmallParagraphIndent",
        "attributes": {
          "category": "font",
          "type": "shortTextXxsmall",
          "item": "paragraphIndent"
        },
        "path": [
          "font",
          "shortTextXxsmall",
          "paragraphIndent"
        ]
      },
      "paragraphSpacing": {
        "value": "0px",
        "type": "number",
        "unit": "pixel",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 0,
          "type": "number",
          "unit": "pixel"
        },
        "name": "FontShortTextXxsmallParagraphSpacing",
        "attributes": {
          "category": "font",
          "type": "shortTextXxsmall",
          "item": "paragraphSpacing"
        },
        "path": [
          "font",
          "shortTextXxsmall",
          "paragraphSpacing"
        ]
      },
      "textCase": {
        "value": "none",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "none",
          "type": "string"
        },
        "name": "FontShortTextXxsmallTextCase",
        "attributes": {
          "category": "font",
          "type": "shortTextXxsmall",
          "item": "textCase"
        },
        "path": [
          "font",
          "shortTextXxsmall",
          "textCase"
        ]
      }
    },
    "h3TopicHeadline": {
      "category": "font",
      "exportKey": "font",
      "fontSize": {
        "value": "20px",
        "type": "number",
        "unit": "pixel",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 20,
          "type": "number",
          "unit": "pixel"
        },
        "name": "FontH3TopicHeadlineFontSize",
        "attributes": {
          "category": "font",
          "type": "h3TopicHeadline",
          "item": "fontSize"
        },
        "path": [
          "font",
          "h3TopicHeadline",
          "fontSize"
        ]
      },
      "textDecoration": {
        "value": "none",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "none",
          "type": "string"
        },
        "name": "FontH3TopicHeadlineTextDecoration",
        "attributes": {
          "category": "font",
          "type": "h3TopicHeadline",
          "item": "textDecoration"
        },
        "path": [
          "font",
          "h3TopicHeadline",
          "textDecoration"
        ]
      },
      "fontFamily": {
        "value": "Inter",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "Inter",
          "type": "string"
        },
        "name": "FontH3TopicHeadlineFontFamily",
        "attributes": {
          "category": "font",
          "type": "h3TopicHeadline",
          "item": "fontFamily"
        },
        "path": [
          "font",
          "h3TopicHeadline",
          "fontFamily"
        ]
      },
      "fontWeight": {
        "value": 700,
        "type": "number",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 700,
          "type": "number"
        },
        "name": "FontH3TopicHeadlineFontWeight",
        "attributes": {
          "category": "font",
          "type": "h3TopicHeadline",
          "item": "fontWeight"
        },
        "path": [
          "font",
          "h3TopicHeadline",
          "fontWeight"
        ]
      },
      "fontStyle": {
        "value": "normal",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "normal",
          "type": "string"
        },
        "name": "FontH3TopicHeadlineFontStyle",
        "attributes": {
          "category": "font",
          "type": "h3TopicHeadline",
          "item": "fontStyle"
        },
        "path": [
          "font",
          "h3TopicHeadline",
          "fontStyle"
        ]
      },
      "fontStretch": {
        "value": "normal",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "normal",
          "type": "string"
        },
        "name": "FontH3TopicHeadlineFontStretch",
        "attributes": {
          "category": "font",
          "type": "h3TopicHeadline",
          "item": "fontStretch"
        },
        "path": [
          "font",
          "h3TopicHeadline",
          "fontStretch"
        ]
      },
      "fontStyleOld": {
        "value": "Bold",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "Bold",
          "type": "string"
        },
        "name": "FontH3TopicHeadlineFontStyleOld",
        "attributes": {
          "category": "font",
          "type": "h3TopicHeadline",
          "item": "fontStyleOld"
        },
        "path": [
          "font",
          "h3TopicHeadline",
          "fontStyleOld"
        ]
      },
      "letterSpacing": {
        "value": "0%",
        "type": "number",
        "unit": "percent",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 0,
          "type": "number",
          "unit": "percent"
        },
        "name": "FontH3TopicHeadlineLetterSpacing",
        "attributes": {
          "category": "font",
          "type": "h3TopicHeadline",
          "item": "letterSpacing",
          "em": "0em"
        },
        "path": [
          "font",
          "h3TopicHeadline",
          "letterSpacing"
        ]
      },
      "lineHeight": {
        "value": "100%",
        "type": "number",
        "unit": "percent",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 100,
          "type": "number",
          "unit": "percent"
        },
        "name": "FontH3TopicHeadlineLineHeight",
        "attributes": {
          "category": "font",
          "type": "h3TopicHeadline",
          "item": "lineHeight",
          "em": "1em"
        },
        "path": [
          "font",
          "h3TopicHeadline",
          "lineHeight"
        ]
      },
      "paragraphIndent": {
        "value": "0px",
        "type": "number",
        "unit": "pixel",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 0,
          "type": "number",
          "unit": "pixel"
        },
        "name": "FontH3TopicHeadlineParagraphIndent",
        "attributes": {
          "category": "font",
          "type": "h3TopicHeadline",
          "item": "paragraphIndent"
        },
        "path": [
          "font",
          "h3TopicHeadline",
          "paragraphIndent"
        ]
      },
      "paragraphSpacing": {
        "value": "0px",
        "type": "number",
        "unit": "pixel",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": 0,
          "type": "number",
          "unit": "pixel"
        },
        "name": "FontH3TopicHeadlineParagraphSpacing",
        "attributes": {
          "category": "font",
          "type": "h3TopicHeadline",
          "item": "paragraphSpacing"
        },
        "path": [
          "font",
          "h3TopicHeadline",
          "paragraphSpacing"
        ]
      },
      "textCase": {
        "value": "uppercase",
        "type": "string",
        "filePath": "properties/design-tokens.json",
        "isSource": true,
        "original": {
          "value": "uppercase",
          "type": "string"
        },
        "name": "FontH3TopicHeadlineTextCase",
        "attributes": {
          "category": "font",
          "type": "h3TopicHeadline",
          "item": "textCase"
        },
        "path": [
          "font",
          "h3TopicHeadline",
          "textCase"
        ]
      }
    }
  },
  "fallbackFont": {
    "fontFamilies": {
      "value": [
        "'SF-Pro'",
        "'Helvetica'",
        "'Arial'",
        "sans-serif"
      ],
      "filePath": "properties/fallbackFont.json",
      "isSource": true,
      "original": {
        "value": [
          "'SF-Pro'",
          "'Helvetica'",
          "'Arial'",
          "sans-serif"
        ]
      },
      "name": "FallbackFontFontFamilies",
      "attributes": {
        "category": "fallbackFont",
        "type": "fontFamilies"
      },
      "path": [
        "fallbackFont",
        "fontFamilies"
      ]
    }
  }
};
