import React, { ReactElement } from "react";
import { Box } from "@mui/material";
import { TextSmall } from "@tbml/components/Typography";
import { Spacer } from "@tbml/components/Spacer";

export function CustomFooter(): ReactElement {
  return (
    <Box sx={{ py: 2 }}>
      <TextSmall color="textSecondary">Note to readers</TextSmall>
      <Spacer size="verticalS" />
      <TextSmall color="textSecondary">
        *Please note that the selection above is based on online news outlets
        and on X (formerly Twitter)** in 12 languages. It is a curated and not
        exhaustive selection made by the IOC Media Intelligence team, except on
        weekends when an external agency handles it. The chosen articles and
        posts are primarily in English, French, German, Spanish and Italian. In
        case there is a regional conversation, the selection may encompass
        articles in additional languages such as Portuguese, Russian, Chinese,
        Japanese, Korean, Hindi, Arabic.
      </TextSmall>
      <Spacer size="verticalS" />
      <TextSmall color="textSecondary">
        The metrics at the top of the Morning Media Pulse indicate the volume of
        online and X/Twitter** mentions related to the Olympic Movement. The
        curve displays the evolution of the Olympic Movement conversation -
        including references to the International Olympic Committee (IOC), the
        forthcoming editions of the Olympic Games or Youth Olympic Games, as
        well as involvement from any interested party - over the past 7 days. To
        ensure fairness between the candidates to the IOC presidency, the
        Morning Media Pulse does not include any press articles focusing on
        individual candidates in the context of the election.
      </TextSmall>
      <Spacer size="verticalS" />
      <TextSmall color="textSecondary">
        ** X/Twitter mentions originating from accounts above 3K followers.
      </TextSmall>
      <Spacer size="verticalS" />
      <TextSmall color="textSecondary">© IOC</TextSmall>
      <Spacer size="verticalS" />
      <TextSmall color="textSecondary">
        Press clippings are licensed for your personal use. License agreements
        do not allow articles to be forwarded to third parties. The distribution
        of this information is prohibited.
      </TextSmall>
    </Box>
  );
}
