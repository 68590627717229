import { DEFAULT_CUSTOMER_HAS_CUSTOM_FOOTER } from "@tbml/api-interface/customer";
import {
  atom,
  SetterOrUpdater,
  useRecoilState,
  useRecoilValue,
} from "@tbml/shared-dependencies/recoil";

const hasCustomFooterAtom = atom({
  key: "hasCustomFooter",
  default: DEFAULT_CUSTOMER_HAS_CUSTOM_FOOTER,
});

export const useHasCustomFooterValue = (): boolean =>
  useRecoilValue(hasCustomFooterAtom);

export const useHasCustomFooterState = (): [
  boolean,
  SetterOrUpdater<boolean>
] => useRecoilState(hasCustomFooterAtom);
